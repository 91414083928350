import React, { useState, useEffect } from "react";
import { FormContainer, TextFieldElement } from "react-hook-form-mui";

type FormProps = {
  name: string;
  ownerFirstName: string;
  ownerLastName: string;
  phone: string;
  email: string;
};

const GeneralDetails = ({
  step,
  setStep,
  formData,
  setFormData,
  existingData,
}: any) => {
  const defaultValues: FormProps = {
    name: existingData.name,
    ownerFirstName: existingData.ownerFirstName,
    ownerLastName: existingData.ownerLastName,
    phone: existingData.phone,
    email: existingData.email,
  };

  const onSubmit = (values: FormProps) => {
    console.log(values);
    setFormData({ ...formData, ...values });
    setStep(step + 1);
  };

  return (
    <div className="w-full flex flex-col gap-6 py-4 ">
      <h6 className="text-[#23282D] poppins text-2xl font-medium leading-9 tracking-tight">
        General Details
      </h6>
      <FormContainer defaultValues={defaultValues} onSuccess={onSubmit}>
        <div className="flex flex-col gap-8 w-full">
          <div className="w-full grid grid-cols-3 gap-16 ">
            <div className="w-full flex flex-col gap-1">
              <TextFieldElement
                name="name"
                placeholder="Enter Distributor Name"
                required
                fullWidth
              />
            </div>
            <div className="w-full flex flex-col gap-1">
              <TextFieldElement
                name="ownerFirstName"
                placeholder="Enter Owner's First Name"
                required
                fullWidth
              />
            </div>
            <div className="w-full flex flex-col gap-1">
              <TextFieldElement
                name="ownerLastName"
                placeholder="Enter Owner's Last Name"
                required
                fullWidth
              />
            </div>
          </div>
          <div className="w-full grid grid-cols-3 gap-16 ">
            <div className="w-full flex flex-col gap-1">
              <TextFieldElement
                name="phone"
                placeholder="Enter Phone Number"
                type="number"
                required
                fullWidth
                validation={{
                  validate: (value) => {
                    console.log(value);
                    let regex = /^[0-9]{10}$/;
                    if (regex.test(value)) {
                      return true;
                    } else {
                      return "Phone enter a valid phone no ";
                    }
                  },
                }}
              />
            </div>
            <div className="w-full flex flex-col gap-1">
              <TextFieldElement
                name="email"
                placeholder="Enter Email"
                type="email"
                required
                fullWidth
                disabled
              />
            </div>
          </div>

          <button
            className="rounded bg-primary py-2 px-16 poppins text-white w-fit "
            type="submit"
          >
            Next
          </button>
        </div>
      </FormContainer>
    </div>
  );
};

export default GeneralDetails;
