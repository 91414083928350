import React, { useEffect, useState } from "react";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import MuiDrawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import NotificationsIcon from "@mui/icons-material/Notifications";
import IconButton from "@mui/material/IconButton";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import LanguageIcon from '@mui/icons-material/Language';
import { callAxios } from "../../utils/axios";
import i18next from "i18next";

import { useNavigate } from "react-router-dom";
import { logout } from "../../store/slices/user";

import { useAppSelector, useAppDispatch } from "../../store/hooks";
import {
  adminFunctions,
  distributorFunctions
} from "../../constants/FunctionConstants";
import moment from "moment";
const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme }) => ({
  marginLeft: drawerWidth,
  width: `calc(100% - ${drawerWidth}px)`,
  backgroundColor: "#fff",
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  //   ...(open && {
  //     marginLeft: drawerWidth,
  //     width: `calc(100% - ${drawerWidth}px)`,
  //     transition: theme.transitions.create(["width", "margin"], {
  //       easing: theme.transitions.easing.sharp,
  //       duration: theme.transitions.duration.enteringScreen,
  //     }),
  //   }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    backgroundColor: "#719FE5",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    }),
  },
}));
const mdTheme = createTheme();

const Sidebar = ({ children }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state?.user);
  const [creditData, setCreditData] = useState({} as any);
  const [adminSide, setadminSide] = useState([])
  const [distriSide, setdistriSide] = useState([])
  const [isDropdownVisible, setDropdownVisible] = useState(false);


  console.log("user", user);
  console.log("adminFunctions", adminFunctions);
  const [open, setOpen] = React.useState<boolean>(false);
  const [openProfile, setOpenProfile] = React.useState<boolean>(false);
  const [anchorElCredit, setAnchorElCredit] =
    React.useState<null | HTMLElement>(null);
  const openCredit = Boolean(anchorElCredit);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    console.log(event.currentTarget);

    if (user?.createdByRole === "Distributor") {
      setAnchorElCredit(event.currentTarget);
    } else {
      setAnchorElCredit(null);
    }
  };
  const handleClose = () => {
    setAnchorElCredit(null);
  };

  const toggleDrawer = () => {
    setOpen(!open);
  };

  // console.log(user);

  const handleLangToggle = () => {
    // alert(isDropdownVisible)
    setDropdownVisible(!isDropdownVisible);
  }

  const handleLanguageChange = (language) => {
    setDropdownVisible(false);
  
    i18next.changeLanguage(language === "fr" ? "fr" : "en");
  };


  const logoutHandler = () => {
    dispatch(logout());
    localStorage.removeItem("token");
    localStorage.removeItem("user");

    navigate("/login");
  };

  const getCreditDetails = async () => {
    try {
      const { data } = await callAxios("get", "payment/getUserCreditLimit");
      console.log(data);
      setCreditData(data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (user?.createdByRole === "Distributor") {
      getCreditDetails();
    }
  }, [user]);


  const [openNested, setOpenNested] = useState({});

  const handleLangBlur = () => {
    setDropdownVisible(false);
  };

  const OpenProfile = () => {
    console.log("object")
    setOpenProfile(!openProfile)
    console.log(openProfile)
  }
  const handleNotificationClick = () => {
    console.log("Notification icon clicked!");
  };
 
  return (
    <>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar position="absolute" color="inherit" elevation={0}>
          <Toolbar
            sx={{
              pr: "24px", // keep right padding when drawer closed
            }}
          >
            {/* <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginRight: "36px",
                ...(open && { display: "none" }),
              }}
            >
              <MenuIcon />
            </IconButton> */}
            <div className="flex justify-end px-8 items-center w-full gap-3">
              <div className="flex gap-4 items-center ">
                {/* <NotificationsIcon className="text-[#2186F2]" /> */}
                <IconButton onClick={handleNotificationClick} color="inherit">
                  <NotificationsIcon className="text-[#2186F2]" />
                </IconButton>
                {/* <div className="cursor-pointer">
                  <AccountCircleIcon fontSize="large" onClick={OpenProfile} />
                </div> */}
                 <div className="cursor-pointer relative">
                  <AccountCircleIcon fontSize="large" onClick={OpenProfile} />
                  
                  {/* Profile Dropdown */}
                  {openProfile && (
                    <div className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg z-10">
                      <div className="p-4">
                        <p className="text-sm font-bold">{user?.name}</p>
                        <p className="text-xs text-gray-500">{user?.email}</p>
                      </div>
                      <div className="border-t mt-2">
                        <button 
                          className="w-full text-left p-2 hover:bg-gray-200" 
                          onClick={logoutHandler}
                        >
                          Logout
                        </button>
                      </div>
                    </div>
                  )}
                </div>
                <div className="cursor-pointer relative lang"  onClick={handleLangToggle} onBlur={handleLangBlur}>
                  <LanguageIcon fontSize="large" />

                  <div className="absolute " >
                  <div className={`dropdown-menu ${isDropdownVisible ? 'block' : 'hidden'} bg-[#f1f1f1]`}>
                      <button className="block w-full text-left p-2 hover:bg-[#719FE5] hover:text-white" onClick={()=>handleLanguageChange('en')}>English</button>
                      <button className="block w-full text-left p-2 hover:bg-[#719FE5] hover:text-white" onClick={()=>handleLanguageChange('fr')}>French</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Menu
              anchorEl={anchorElCredit}
              id="credit"
              open={openCredit}
              onClose={handleClose}
              onClick={handleClose}
              PaperProps={{
                elevation: 0,

                sx: {
                  padding: "0px",
                  overflow: "visible",
                  width: 450,
                  filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                  mt: 1.5,
                  "& .MuiAvatar-root": {
                    width: 42,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  "&:before": {
                    content: '""',
                    display: "block",
                    position: "absolute",
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: "#fff",
                    transform: "translateY(-50%) rotate(45deg)",
                    zIndex: 0,
                  },

                  "& .MuiMenu-list": {
                    padding: "0px",
                  },
                },
              }}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
              <div className="w-full rounded flex flex-col p-0 ">
                <div className="w-full py-4 bg-primary flex justify-center">
                  <h6 className="poppins text-xl font-medium text-white ">
                    Credit Based Payment
                  </h6>
                </div>
                <div className="w-full flex flex-col p-4 gap-6">
                  <div className="flex gap-4 items-center">
                    <p className="text-black text-md poppins font-normal">
                      Approved Limit
                    </p>
                    <p className="text-black text-md poppins font-light">
                      {creditData?.creditLimit}
                    </p>
                  </div>
                  <div className="w-full bg-[#A5A5A5] rounded-full h-4">
                    <div
                      className="bg-[#A7EC71] h-4 rounded-full"
                      style={{ width: `${creditData?.usedPercentage}%` }}
                    ></div>
                  </div>
                  <div className="w-full grid grid-cols-2 gap-2 ">
                    <div className="w-full flex gap-2 items-center">
                      <div className="p-2 rounded-full bg-[#A7EC71]"></div>
                      <p className="text-sm poppins font-normal">Used Amount</p>
                      <p className="text-sm poppins font-light">
                        {creditData?.usedCredit}
                      </p>
                    </div>
                    <div className="w-full flex gap-3 items-center">
                      <div className="p-2 rounded-full bg-[#A5A5A5]"></div>
                      <p className="text-sm poppins font-normal">
                        Available Amount
                      </p>
                      <p className="text-sm poppins font-light">
                        {creditData?.availableCredit}
                      </p>
                    </div>
                  </div>
                  <div className="w-full grid grid-cols-2 gap-3">
                    <div className="w-full flex flex-col gap-1">
                      <p className="text-sm poppins font-normal">
                        Bill Generation Date
                      </p>
                      <button className="w-full text-white poppins text-md font-medium rounded-md bg-primary flex items-center justify-center py-2">
                        {moment()
                          .clone()
                          .add(1, "month")
                          .startOf("month")
                          .format("DD MMM YYYY")}
                      </button>
                    </div>
                    <div className="w-full flex flex-col gap-1">
                      <p className="text-sm poppins font-normal">Due Date</p>
                      <button className="w-full text-white poppins text-md font-medium rounded-md bg-primary flex items-center justify-center py-2">
                        {moment()
                          .clone()
                          .add(1, "month")
                          .startOf("month")
                          .clone()
                          .add(creditData?.gracePeriod, "days")
                          .format("DD MMM YYYY")}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Menu>
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" color="primary" open={true}>
          <Toolbar
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              px: [1],
            }}
          >
            <div className="w-full flex py-10 sitems-center justify-center relative overflow-hidden">
              <div className="w-[340px] h-[340px] absolute bg-[#55555510] -top-40 left-20 z-100 rounded-full "></div>
              {/* <div className="w-[340px] h-[340px] absolute bg-[#55555510] bottom-20 right-10 z-100 rounded-full "></div> */}
              <div className="p-2 rounded-full w-fit ">
                <img
                  src="/assets/Images/roboticsLogo.png"
                  className="w-20 h-auto max-w-full mx-auto block"
                  alt="logo"
                />
              </div>
            </div>

            {/* <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon />
            </IconButton> */}
          </Toolbar>

          <div className="h-full py-6 flex flex-col items-center justify-between relative">
            <div className="w-[340px] h-[340px] absolute bg-[#55555510] top-20  right-12 z-100 rounded-full "></div>

            <div className="flex gap-6 flex-col  h-full z-[120] ">
              <>
                {user?.createdByRole === "Distributor" &&
                  distributorFunctions.map((item: any) => {
                    return (
                      <>
                        {user?.function_.includes(item.name) && (
                          <div
                            className="w-full px-2 flex gap-4 items-start cursor-pointer"
                            onClick={() => navigate(item?.route)}
                            key={item?.name}
                          >
                            <img src={item?.logo} alt="logo" className="w-6" />
                            <p className="inter text-base leading-6 text-white font-normal ">
                              {item?.name}
                            </p>
                          </div>
                        )}
                      </>
                    );
                  })}
                {user?.createdByRole === "Admin" &&
                  adminFunctions.map((item: any) => {
                    return (
                      <> 
                      {/* {item.name} */}
                        {user?.function_.includes(item.name) && (
                          <div
                            className="w-full px-2 flex gap-4 items-start cursor-pointer"
                            onClick={() => navigate(item?.route)}
                            key={item?.name}
                          >
                            <img src={item?.logo} alt="logo" className="w-6" />
                            <p className="inter text-base leading-6 text-white font-normal ">
                              {localStorage.getItem('i18nextLng') === "fr" ? item.frenchName : item.name}
                            </p>
                          </div>
                        )}
                      </>
                    );
                  })}
              </>
            </div>
            <button
              className="flex gap-2 items-center text-center"
              onClick={logoutHandler}
            >
              <p className="inter text-base leading-6 text-white font-normal">
                Logout
              </p>
              <img src="/assets/Logo/exit.svg" alt="logout" />
            </button>
          </div>
        </Drawer>
        <Box
          component="main"
          sx={{
            backgroundColor: "#F1F1F1",
            flexGrow: 1,
            // height: "100%",
            minHeight: "100vh",
            overflow: "auto",
          }}
        >
          <Toolbar />
          {/* <div  style={{marginLeft:"1rem",marginRight:"0"}} > */}
          {children}
          {/* </div> */}
        </Box>
      </Box>
    </>
  );
};

export default Sidebar;
