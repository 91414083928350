import React, { useState, useEffect } from "react";
import Sidebar from "../../Layout/Sidebar";
import Loading from "react-fullscreen-loading";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { useNavigate } from "react-router-dom";
import { callAxios } from "../../../utils/axios";
import { toastMessage } from "../../../utils/toast";
import Pagination from "@mui/material/Pagination";
import moment from "moment";
import { TextField } from "@mui/material";
import { useTranslation } from "react-i18next";

const CustomTableHeadCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: "transparent", // Customize cell background color
  fontWeight: "bold", // Customize cell text styling
  fontFamily: "Poppins",
  fontSize: "16px",
  lineHeight: "24px",
  borderBottom: "none",
  color: "#000",
}));
const CustomTableBodyCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: "#F4F4F4", // Customize cell background color
  fontFamily: "Poppins",
  fontSize: "16px",
  lineHeight: "24px",
  borderBottom: "none",
}));

const CustomTableHeadRow = styled(TableRow)(({ theme }) => ({
  "&:hover": {
    backgroundColor: "#E0E0E0",
  },
  border: "none",
}));
const CustomTableBodyRow = styled(TableRow)(({ theme }) => ({
  marginBottom: "10px",
}));

const AppointmentsTable = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState(false);
  const [appointments, setAppointments] = useState([] as any);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [startDate, setStartDate] = useState(null as any);
  const [endDate, setEndDate] = useState(null as string | null);

  const {t} = useTranslation()

  const getAppointments = async () => {
    try {
      setLoading(true);

      const { data } = await callAxios(
        "get",
        `${
          startDate && endDate
            ? `appointment/getAllAppointments?startDate=${startDate}&endDate=${endDate}&page=${page}&limit=10`
            : `appointment/getAllAppointments?page=${page}&limit=10`
        } `
      );
      console.log(data);
      setAppointments(data?.appointments);
      setTotalPages(data?.totalPage);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      toastMessage(
        error?.response?.data?.message || "Something went wrong.",
        "error"
      );
    }
  };

  useEffect(() => {
    getAppointments();
  }, [checked, page]);

  const updateAppointment = async (id: string, status: string) => {
    try {
      setLoading(true);
      const { data } = await callAxios(
        "put",
        `appointment/updateAppointment/${id}`,
        {
          status,
        }
      );
      toastMessage("success", data.message);
      setLoading(false);
      setChecked(!checked);
    } catch (error) {
      console.log(error);
      setLoading(false);
      toastMessage("error", error.response.data.message);
    }
  };

  return (
    <>
      <Loading loading={loading} background="#fff" loaderColor="#719FE5" />
      <Sidebar>
        <div className="py-6 px-4 flex flex-col gap-8">
          <div className="w-full flex justify-between items-center">
            <div className="flex flex-col gap-2">
              <h1 className="text-[#23282D] poppins text-3xl font-semibold tracking-tight ">
              {t('adminRoute.appointmentpage.pageTitle')}
              </h1>
              <p className="inter tracking-tight text-sm color-[#23282D]">
              {t('adminRoute.appointmentpage.pageDescription')}
              </p>
            </div>
          </div>
          <div className="w-full bg-white rounded shadow flex flex-col p-8 gap-12">
            <div className="flex items-center justify-between ">
              <h5 className="poppins text-2xl ">{t('adminRoute.appointmentpage.appointments.title')}</h5>
              <div className="flex gap-6 items-center">
                <TextField
                  label={t('adminRoute.appointmentpage.appointments.dateRangeLabels.from')}
                  type="date"
                  size="small"
                  InputLabelProps={{
                    shrink: true, // This makes the label float as you select a date
                  }}
                  onChange={(e) => setStartDate(e.target.value)}
                  value={startDate}
                />
                <TextField
                  label={t('adminRoute.appointmentpage.appointments.dateRangeLabels.to')}
                  type="date"
                  size="small"
                  InputLabelProps={{
                    shrink: true, // This makes the label float as you select a date
                  }}
                  onChange={(e) => setEndDate(e.target.value)}
                  value={endDate}
                />
              </div>
              <button
                onClick={getAppointments}
                className="w-40 py-2 flex justify-center rounded-lg shadow items-center text-white poppins bg-primary     "
              >
               {t('adminRoute.appointmentpage.appointments.viewButton')}
              </button>
            </div>
            <Table
              sx={{
                borderSpacing: "0 15px !important",
                borderCollapse: "separate !important",
              }}
            >
              <TableHead>
                <CustomTableHeadRow>
                  <CustomTableHeadCell> {t('adminRoute.appointmentpage.appointments.tableHeaders.name')}</CustomTableHeadCell>
                  <CustomTableHeadCell> {t('adminRoute.appointmentpage.appointments.tableHeaders.status')}</CustomTableHeadCell>
                  <CustomTableHeadCell> {t('adminRoute.appointmentpage.appointments.tableHeaders.date')}</CustomTableHeadCell>
                  <CustomTableHeadCell> {t('adminRoute.appointmentpage.appointments.tableHeaders.time')}</CustomTableHeadCell>
                  <CustomTableHeadCell> {t('adminRoute.appointmentpage.appointments.tableHeaders.agenda')}</CustomTableHeadCell>
                </CustomTableHeadRow>
              </TableHead>
              <TableBody>
                {appointments.map((appointment: any) => {
                  return (
                    <CustomTableBodyRow
                      sx={{
                        marginBottom: "10px !important",
                      }}
                    >
                      <CustomTableBodyCell
                        sx={{
                          borderTopLeftRadius: "10px",
                          borderBottomLeftRadius: "10px",
                        }}
                      >
                        {appointment?.user?.name || "N/A"}
                      </CustomTableBodyCell>
                      <CustomTableBodyCell>
                        {appointment?.status}
                      </CustomTableBodyCell>
                      <CustomTableBodyCell>
                        {moment(appointment.date).format("DD.MM.YYYY")}
                      </CustomTableBodyCell>
                      <CustomTableBodyCell>
                        {appointment?.time}
                      </CustomTableBodyCell>
                      <CustomTableBodyCell
                        sx={{
                          borderTopRightRadius: "10px",
                          borderBottomRightRadius: "10px",
                        }}
                      >
                        {appointment?.agenda}
                      </CustomTableBodyCell>
                      <CustomTableHeadCell>
                        {appointment?.status === "pending" && (
                          <div className="w-full flex gap-2 justify-center">
                            <button
                              onClick={() => {
                                updateAppointment(appointment._id, "approved");
                              }}
                              className="bg-primary py-1 px-4 rounded-md text-white poppins text-sm font-normal"
                            >
                               {t('adminRoute.appointmentpage.appointments.approvalActions.approve')}
                            </button>
                            <button
                              onClick={() => {
                                updateAppointment(appointment._id, "rejected");
                              }}
                              className="bg-red-500 py-1 px-4 rounded-md text-white poppins text-sm font-normal"
                            >
                               {t('adminRoute.appointmentpage.appointments.approvalActions.reject')}
                            </button>
                          </div>
                        )}
                      </CustomTableHeadCell>
                    </CustomTableBodyRow>
                  );
                })}
              </TableBody>
            </Table>
            <div className="w-full flex justify-end">
              <Pagination
                count={totalPages}
                variant="outlined"
                shape="rounded"
                color="primary"
                onChange={(e, value) => setPage(value)}
              />
            </div>
          </div>
        </div>
      </Sidebar>
    </>
  );
};

export default AppointmentsTable;
