import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";

import { BrowserRouter } from "react-router-dom";
import Theme from "./utils/theme";
import { ThemeProvider } from "@mui/material/styles";
import { Provider } from "react-redux";
import { ConfirmProvider } from "material-ui-confirm";
import { store } from "./store/store";
import i18n, { InitOptions } from 'i18next';
import LanguageDetector, { DetectorOptions } from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';
import { I18nextProvider } from 'react-i18next';

const i18nOptions: InitOptions<object> = {
  lng: localStorage.getItem('i18nextLng') || 'en',
  fallbackLng: "en",
  detection: {
    order: ["localStorage", "htmlTag", "path", "cookie", "subdomain"],
    caches: ["localStorage"],
    checkWhitelist: true,
  } as DetectorOptions,
  interpolation: {
    escapeValue: false,
  },
  backend: {
    loadPath: "/lang/{{lng}}.json",
  },
  // whitelist: ["en", "fr"] as string[], 
};

i18n
  .use(LanguageDetector)
  .use(HttpApi)
  .init(i18nOptions);





const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <Provider store={store}>
    <ThemeProvider theme={Theme}>
      <BrowserRouter>
        <ConfirmProvider>
          <Suspense fallback={<div>Loading...</div>}>
            <I18nextProvider i18n={i18n}>
              <App />
            </I18nextProvider>
          </Suspense>
        </ConfirmProvider>
      </BrowserRouter>
    </ThemeProvider>
  </Provider>
);
