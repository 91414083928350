import React, { useState, useEffect } from "react";
import Sidebar from "../../Layout/Sidebar";
import Loading from "react-fullscreen-loading";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { Form, useNavigate } from "react-router-dom";
import { callAxios } from "../../../utils/axios";
import { toastMessage } from "../../../utils/toast";
import { useConfirm } from "material-ui-confirm";
import { MenuItem, Pagination, Select, Switch, TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { InputAdornment } from "@mui/material";
import OutlinedInput from "@mui/material/OutlinedInput";
import moment from "moment";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";
import {
  FormContainer,
  TextFieldElement,
  SelectElement,
} from "react-hook-form-mui";
const CustomTableHeadCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: "#fff", // Customize cell background color
  fontWeight: "bold", // Customize cell text styling
  fontFamily: "Poppins",
  fontSize: "16px",
  lineHeight: "24px",
  borderBottom: "none",
  color: "black",
}));
const CustomTableBodyCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: "#F4F4F4", // Customize cell background color
  fontFamily: "Poppins",
  fontSize: "16px",
  lineHeight: "24px",
  borderBottom: "none",
}));

const CustomTableHeadRow = styled(TableRow)(({ theme }) => ({
  "&:hover": {
    backgroundColor: "#E0E0E0",
  },
  border: "none",
}));
const CustomTableBodyRow = styled(TableRow)(({ theme }) => ({
  marginBottom: "10px",
}));

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "#fff",
  borderRadius: "12px",
  boxShadow: 24,
  p: 4,
};

type FormProps = {
  status: string;
};

const OrdersTable = () => {
  const navigate = useNavigate();
  const confirm = useConfirm();

  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState(false);
  const [orders, setOrders] = useState([] as any);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState({} as any);
  const [defaultValues, setDefaultValues] = useState({} as FormProps);
  const [viewOpen, setViewOpen] = useState(false);
  const [distributorDetails, setDistributorDetails] = useState({
    name: "",
    email: "",
    phone: "",
    status: "",
  });
  const getAllProducts = async () => {
    try {
      setLoading(true);
      const { data } = await callAxios(
        "get",
        `order/getAllOrders?limit=10&page=${page}`
      );
      console.log(data);
      setOrders(data?.orders);
      setTotalPages(data?.totalPages);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      toastMessage(
        error?.response?.data?.message || "Something went wrong.",
        "error"
      );
    }
  };

  useEffect(() => {
    getAllProducts();
  }, [checked, page]);

  const onSubmit = async (formValues: any) => {
    try {
      setLoading(true);
      const { data } = await callAxios(
        "put",
        `order/updateOrderStatus/${selected?._id}`,
        formValues
      );
      console.log(data);
      toastMessage("Order Status Updated Successfully", "success");
      setOpen(false);
      setLoading(false);
      setChecked(!checked);
    } catch (error) {
      console.log(error);
      setLoading(false);
      toastMessage(
        error?.response?.data?.message || "Something went wrong.",
        "error"
      );
    }
  };
  const handleViewClick = (order: any) => {
    setDistributorDetails({
      name: order?.distributor?.name || "",
      email: order?.distributor?.email || "",
      phone: order?.distributor?.phone || "",
      status: order?.status || "",
    });
    setViewOpen(true);
  };
  return (
    <>
      <Loading loading={loading} background="#fff" loaderColor="#719FE5" />
      <Sidebar>
        <div className="py-6 px-4 flex flex-col gap-8">
          <div className="w-full flex justify-between items-center">
            <div className="flex flex-col gap-2">
              <h1 className="text-[#23282D] poppins text-3xl font-semibold tracking-tight ">
                {t("adminRoute.purchaseOrder.title")}
              </h1>
              <p className="inter tracking-tight text-sm color-[#23282D]">
                {t("adminRoute.purchaseOrder.description")}{" "}
              </p>
            </div>
            <button
              className="flex gap-1 h-16 px-2 items-center justify-center bg-white   rounded-lg poppins leading-6 "
              style={{
                boxShadow: "0px 4px 8px 0px rgba(0, 0, 0, 0.20)",
              }}
              onClick={() => navigate("/admin/generate-order")}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M12.1094 7V17M17.1094 12L7.10938 12"
                  stroke="#4B4B4B"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <p className="wrap break-words">
                {" "}
                {t("adminRoute.purchaseOrder.createOrderButton.label")}{" "}
              </p>
            </button>
          </div>
          <div className="w-full flex flex-col gap-3 ">
            <div className="w-full bg-white p-3 rounded shadow">
              <Table
                sx={{
                  borderSpacing: "0 15px !important",
                  borderCollapse: "separate !important",
                }}
              >
                <TableHead>
                  <CustomTableHeadRow>
                    {/* <CustomTableHeadCell>ORDER NUMBER</CustomTableHeadCell> */}
                    {/* <CustomTableHeadCell>DEALER NAME</CustomTableHeadCell> */}
                    <CustomTableHeadCell>
                      {" "}
                      {t(
                        "adminRoute.purchaseOrder.tableHeaders.distributorName"
                      )}
                    </CustomTableHeadCell>
                    <CustomTableHeadCell>
                      {t("adminRoute.purchaseOrder.tableHeaders.address")}
                    </CustomTableHeadCell>
                    <CustomTableHeadCell>
                      {t("adminRoute.purchaseOrder.tableHeaders.price")}
                    </CustomTableHeadCell>
                    <CustomTableHeadCell>
                      {t("adminRoute.purchaseOrder.tableHeaders.status")}
                    </CustomTableHeadCell>
                    <CustomTableHeadCell>
                      {t("adminRoute.purchaseOrder.tableHeaders.orderDate")}
                    </CustomTableHeadCell>
                    <CustomTableHeadCell></CustomTableHeadCell>
                  </CustomTableHeadRow>
                </TableHead>
                <TableBody>
                  {orders.map((order: any) => {
                    return (
                      <CustomTableBodyRow
                        sx={{
                          marginBottom: "10px !important",
                        }}
                        key={order?._id}
                      >
                        {/* <CustomTableBodyCell
                          sx={{
                            borderTopLeftRadius: "10px",
                            borderBottomLeftRadius: "10px",
                          }}
                        >
                          {order.skuName}
                        </CustomTableBodyCell> */}
                        {/* <CustomTableBodyCell>
                          {order?.skuCode}
                        </CustomTableBodyCell> */}
                        <CustomTableBodyCell>
                          {order?.distributor?.name}
                        </CustomTableBodyCell>
                        <CustomTableBodyCell>
                          {order?.address?.name}
                        </CustomTableBodyCell>
                        <CustomTableBodyCell>
                          {order?.totalPrice}
                        </CustomTableBodyCell>
                        <CustomTableBodyCell>
                          {order?.status?.toUpperCase()}
                        </CustomTableBodyCell>
                        <CustomTableBodyCell>
                          {moment(order?.createdAt).format("DD.MM.YYYY")}
                        </CustomTableBodyCell>
                        <CustomTableBodyCell
                          sx={{
                            borderTopRightRadius: "10px",
                            borderBottomRightRadius: "10px",
                          }}
                        ></CustomTableBodyCell>
                        <CustomTableHeadCell>
                          <div className="w-full flex gap-3 ">
                            <button
                              className="bg-primary py-2 px-8 rounded-md text-white poppins text-sm font-normal"
                              onClick={() => handleViewClick(order)}
                            >
                              {t(
                                "adminRoute.marketingCampaigns.tableHeaders.viewButton"
                              )}
                            </button>
                            <img
                              src="/assets/Icons/edit.svg"
                              alt="edit"
                              className="w-8 h-8 cursor-pointer"
                              onClick={() => {
                                setSelected(order);
                                setDefaultValues({
                                  ...defaultValues,
                                  status: order?.status,
                                });
                                setOpen(true);
                              }}
                            />
                          </div>
                        </CustomTableHeadCell>
                      </CustomTableBodyRow>
                    );
                  })}
                </TableBody>
              </Table>
            </div>
            <div className="w-full flex justify-end">
              <Pagination
                count={totalPages}
                variant="outlined"
                shape="rounded"
                color="primary"
                onChange={(e, value) => setPage(value)}
              />
            </div>
          </div>
        </div>
      </Sidebar>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <FormContainer defaultValues={defaultValues} onSuccess={onSubmit}>
            <div className="flex flex-col gap-4  w-full">
              <h3 className="text-[#23282D] poppins text-center text-2xl font-medium leading-9 tracking-tight">
                Update Order Status
              </h3>
              <div className="w-full">
                <SelectElement
                  name="status"
                  label="Status"
                  size="small"
                  fullWidth
                  required
                  defaultValue={defaultValues.status}
                  options={[
                    { label: "Pending", id: "pending" },
                    { label: "Delivered", id: "delivered" },
                    { label: "Processing", id: "processing" },
                    { label: "Shipped", id: "shipped" },
                    { label: "Cancelled", id: "cancelled" },
                  ]}
                />
              </div>
              <div className="w-full flex justify-end">
                <button className="py-1 px-4 poppins rounded text-md font-medium text-white bg-primary ">
                  Save
                </button>
              </div>
            </div>
          </FormContainer>
        </Box>
      </Modal>
      {/* View Distributor Details Modal */}
      <Modal
        open={viewOpen}
        onClose={() => setViewOpen(false)}
        aria-labelledby="view-modal-title"
        aria-describedby="view-modal-description"
      >
        <Box
          sx={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: { xs: "90%", sm: "80%", md: "60%" },
            maxWidth: "500px",
            bgcolor: "background.paper",
            borderRadius: 2,
            boxShadow: 24,
            p: 4,
            overflow: "auto",
          }}
        >
          <div className="flex flex-col gap-2 w-full">
            <h3 className="text-[#23282D] poppins text-center text-2xl font-medium leading-9 tracking-tight">
              Distributor Details
            </h3>
            <div>
              <TextField
                disabled
                label="Name"
                value={distributorDetails.name}
                variant="outlined"
                fullWidth
                sx={{ mb: 2 }}
              />
              <TextField
                disabled
                label="Email"
                value={distributorDetails.email}
                variant="outlined"
                fullWidth
                sx={{ mb: 2 }}
              />
              <TextField
                disabled
                label="Phone"
                value={distributorDetails.phone}
                variant="outlined"
                fullWidth
                sx={{ mb: 2 }}
              />
              <TextField
                disabled
                label="Status"
                value={distributorDetails.status}
                variant="outlined"
                fullWidth
                sx={{ mb: 2 }}
              />
            </div>
            <div className="w-full flex justify-end">
              <button
                className="py-1 px-4 poppins rounded text-md font-medium text-white bg-primary"
                onClick={() => setViewOpen(false)}
              >
                Close
              </button>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default OrdersTable;
