import React, { useState, useEffect } from "react";
import Sidebar from "../../Layout/Sidebar";
import { useNavigate } from "react-router-dom";
import { callAxios } from "../../../utils/axios";
import Loading from "react-fullscreen-loading";
import { toastMessage } from "../../../utils/toast";
import { useConfirm } from "material-ui-confirm";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Pagination from "@mui/material/Pagination";
import moment from "moment";
import { useTranslation } from "react-i18next";

const CustomTableHeadCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: "transparent", // Customize cell background color
  fontWeight: "bold", // Customize cell text styling
  fontFamily: "Poppins",
  fontSize: "16px",
  lineHeight: "24px",
  borderBottom: "none",
  color: "#000",
}));
const CustomTableBodyCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: "#F4F4F4", // Customize cell background color
  fontFamily: "Poppins",
  fontSize: "16px",
  lineHeight: "24px",
  borderBottom: "none",
}));

const CustomTableHeadRow = styled(TableRow)(({ theme }) => ({
  "&:hover": {
    backgroundColor: "#E0E0E0",
  },
  border: "none",
}));
const CustomTableBodyRow = styled(TableRow)(({ theme }) => ({
  marginBottom: "10px",
}));

const ContractsTable = () => {
  const navigate = useNavigate();
  const confirm = useConfirm();

  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [contracts, setContracts] = useState([] as any);

  const {t} = useTranslation()

  const getAllContracts = async () => {
    try {
      setLoading(true);
      const { data } = await callAxios(
        "get",
        `contract/getAllContracts?page=${page}&limit=10`
      );
      console.log(data);
      setContracts(data?.contracts);
      setLoading(false);
      setTotalPages(data?.totalPages);
    } catch (error) {
      console.log(error);
      setLoading(false);
      toastMessage(
        error?.response?.data?.message || "Something went wrong.",
        "error"
      );
    }
  };

  useEffect(() => {
    getAllContracts();
  }, [checked, page]);

  return (
    <>
      <Loading loading={loading} background="#fff" loaderColor="#5046E5" />
      <Sidebar>
        <div className="py-6 px-4 flex flex-col gap-8">
          <div className="w-full flex justify-between items-center">
            <div className="flex flex-col gap-2">
              <h1 className="text-[#23282D] poppins text-3xl font-semibold tracking-tight ">
              {t('adminRoute.contractsPage.pageTitle')}
              </h1>
              <p className="inter tracking-tight text-sm color-[#23282D]">
              {t('adminRoute.contractsPage.pageDescription')}              </p>
            </div>
            <button
              className="flex gap-1 h-12 px-2 items-center justify-center bg-white   rounded-lg poppins leading-6 "
              style={{
                boxShadow: "0px 4px 8px 0px rgba(0, 0, 0, 0.20)",
              }}
              onClick={() => navigate("/admin/create-contract")}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M12.1094 7V17M17.1094 12L7.10938 12"
                  stroke="#4B4B4B"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <p className="wrap break-words">   {t('adminRoute.contractsPage.newContractButton.label')}</p>
            </button>
          </div>
          <div className="w-full bg-white p-4 rounded shadow ">
            <Table
              sx={{
                borderSpacing: "0 15px !important",
                borderCollapse: "separate !important",
              }}
            >
              <TableHead>
                <CustomTableHeadRow>
                  <CustomTableHeadCell> {t('adminRoute.contractsPage.tableHeaders.contractId')}</CustomTableHeadCell>
                  <CustomTableHeadCell>{t('adminRoute.contractsPage.tableHeaders.dealer')}</CustomTableHeadCell>
                  <CustomTableHeadCell>{t('adminRoute.contractsPage.tableHeaders.date')}</CustomTableHeadCell>
                  <CustomTableHeadCell>{t('adminRoute.contractsPage.tableHeaders.status')}</CustomTableHeadCell>
                  <CustomTableHeadCell></CustomTableHeadCell>
                </CustomTableHeadRow>
              </TableHead>
              <TableBody>
                {contracts?.map((contract: any) => {
                  return (
                    <CustomTableBodyRow
                      sx={{
                        marginBottom: "10px !important",
                      }}
                      key={contract?.id}
                    >
                      <CustomTableBodyCell
                        sx={{
                          borderTopLeftRadius: "10px",
                          borderBottomLeftRadius: "10px",
                        }}
                      >
                        {contract?.contractId}
                      </CustomTableBodyCell>
                      <CustomTableBodyCell>
                        {contract?.user?.name}
                      </CustomTableBodyCell>
                      <CustomTableBodyCell>
                        {moment(contract?.createdAt).format("DD.MM.YYYY")}
                      </CustomTableBodyCell>
                      <CustomTableBodyCell
                        sx={{
                          borderTopRightRadius: "10px",
                          borderBottomRightRadius: "10px",
                        }}
                      >
                        <div className={`flex gap-2 items-center `}>
                          <div
                            className={`p-1 h-1 w-1 rounded-full ${
                              contract?.status === "Signed"
                                ? "bg-[#93E277]"
                                : contract?.status === "Rejected"
                                ? "bg-[#EF8787]"
                                : "bg-[#EECB98]"
                            } `}
                          ></div>
                          {contract?.status}
                        </div>
                      </CustomTableBodyCell>

                      <CustomTableHeadCell>
                        <a
                          href={
                            contract?.status !== "Pending"
                              ? contract?.signedUrl
                              : contract?.unSignedUrl
                          }
                          target="_blank"
                          className="bg-[#719FE5] cursor-pointer w-full py-1 font-light px-2 text-sm text-white poppins leading-6 rounded"
                        >
                          {t('adminRoute.contractsPage.viewButton.label')}
                        </a>
                      </CustomTableHeadCell>
                    </CustomTableBodyRow>
                  );
                })}
              </TableBody>
            </Table>
            <div className="w-full flex justify-end">
              <Pagination
                count={totalPages}
                variant="outlined"
                shape="rounded"
                color="primary"
                onChange={(e, value) => setPage(value)}
              />
            </div>
          </div>
        </div>
      </Sidebar>
    </>
  );
};

export default ContractsTable;
