import React, { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";

import {
    FormContainer,
    TextFieldElement,
    SelectElement,
} from "react-hook-form-mui";

import Loading from "react-fullscreen-loading";
import { useTranslation } from "react-i18next";




const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
    bgcolor: "#fff",
    borderRadius: "12px",
    boxShadow: 24,
    p: 4,
};

const ViewRoles = ({ checked, setChecked, setOpen, existingUser }: any) => {

  const {t} = useTranslation()
    const [loading, setLoading] = useState(false);
    const [roleOptions, setRoleOptions] = useState([] as any);
   console.log("view roles", existingUser)
  



    return (
        <Box sx={style}>
            <Loading loading={loading} background="#fff" loaderColor="#719FE5" />
            
            <div className="flex flex-col gap-4 w-full">
          <div className="w-full grid grid-cols-3 gap-16 ">
            <div className="w-full flex flex-col gap-1">
            <Typography variant="body1">
              <strong>{t('DistributerRoute.rolesPage.viewRolesPage.details.nameLabel')} :</strong> <br />{existingUser?.name}
            </Typography>
            </div>
            <div className="w-full flex flex-col gap-1">
            <Typography variant="body1">
              <strong>{t('DistributerRoute.rolesPage.viewRolesPage.details.emailLabel')}:</strong><br /> {existingUser?.email}
            </Typography>
            </div>
            <div className="w-full flex flex-col gap-1">
            <Typography variant="body1">
              <strong>{t('DistributerRoute.rolesPage.viewRolesPage.details.remarksLabel')}:</strong><br /> {existingUser?.remarks}
            </Typography>
            </div>
          </div>

          <div className="w-full grid grid-cols-3 gap-16 ">
            <div className="w-full flex flex-col gap-1">
            <Typography variant="body1">
              <strong>{t('DistributerRoute.rolesPage.viewRolesPage.details.roleLabel')}:</strong> <br/> {existingUser?.role}
            </Typography>
            </div>
            <div className="w-full flex flex-col gap-1">
            <Typography variant="body1">
              <strong>{t('DistributerRoute.rolesPage.viewRolesPage.details.statusLabel')}:</strong> <br/> {existingUser?.status}
            </Typography>
            </div>
          </div>


        </div>
        </Box >
    );
};

export default ViewRoles;
