import React, { useState, useEffect } from "react";
import {
  FormContainer,
  TextFieldElement,
  SelectElement,
  DatePickerElement
} from "react-hook-form-mui";
import { callAxios } from "../../../../utils/axios";
import { toastMessage } from "../../../../utils/toast";

import moment from "moment";

type FormProps = {
  batchNumber: string;
  price: number;
  quantity: number;
  manufacturingDate: string;
  warrantyPeriodFromBilling: string;
};

const BatchDetails = ({ step, setStep, formData, setFormData,existingData }: any) => {
  const defaultValues: FormProps = {
    batchNumber: existingData?.batchNumber || "",
    price: existingData.price,
    quantity: existingData.quantity,
    manufacturingDate: moment(existingData.manufacturingDate).format("YYYY-MM-DD"),
    warrantyPeriodFromBilling: existingData.warrantyPeriodFromBilling,
  };
  const onSubmit = (values: FormProps) => {
    console.log(values);
    setFormData({ ...formData, ...values });
    setStep(step + 1);
  };

  return (
    <div className="w-full flex flex-col gap-6 py-4 ">
      <h6 className="text-[#23282D] poppins text-2xl font-medium leading-9 tracking-tight">
        Batch Details
      </h6>
      <FormContainer defaultValues={defaultValues} onSuccess={onSubmit}>
        <div className="flex flex-col gap-8 w-full">
          <div className="w-full grid grid-cols-3 gap-16 ">
            <div className="w-full flex flex-col gap-1">
              <TextFieldElement
                name="batchNumber"
                placeholder="Batch Number"
                required
                fullWidth
              />
            </div>
            <div className="w-full flex flex-col gap-1">
              <TextFieldElement
                name="price"
                placeholder="Price"
                required
                type="number"
                fullWidth
              />
            </div>
            <div className="w-full flex flex-col gap-1">
              <TextFieldElement
                name="quantity"
                placeholder="Quantity"
                required
                type="number"
                fullWidth
              />
            </div>
          </div>
          <div className="w-full grid grid-cols-3 gap-16 ">
            <div className="w-full flex flex-col gap-1">
              <TextFieldElement
                name="manufacturingDate"
                placeholder="Manufacturing Date"
                // label="Manufacturing Date"
                required
                type="date"
                fullWidth
                label="Manufacturing Date"
                
              />
            </div>
            <div className="w-full flex flex-col gap-1">
              <TextFieldElement
                name="warrantyPeriodFromBilling"
                placeholder="Warranty Period From Billing"
                required
                type="number"
                fullWidth
              />
            </div>
        
          </div>
          <button
            className="rounded bg-primary py-2 px-16 poppins text-white w-fit "
            type="submit"
          >
            Next
          </button>
        </div>
      </FormContainer>
    </div>
  );
};

export default BatchDetails;
