import React, { useState, useEffect } from "react";
import { Avatar } from "@mui/material";
import { db } from "../../../utils/firebase";
import { toastMessage } from "../../../utils/toast";
import {
  addDoc,
  collection,
  getDocs,
  onSnapshot,
  orderBy,
  query,
  where,
  updateDoc,
  doc,
} from "@firebase/firestore";

const UserChat = ({ selectedUser, setSelectedUser }: any) => {
  const [message, setMessage] = useState("");
  const [allMessages, setAllMessages] = useState([]);

  const sendMessage = async (e) => {
    e.preventDefault();
    if (!message) return toastMessage("Please enter message", "error");

    try {
      const data = await addDoc(collection(db, selectedUser?._id), {
        message,
        sender: "admin",
        receiver: selectedUser?._id,
        timestamp: new Date(),
      });
      setMessage("");
      console.log(data);
    } catch (error) {
      console.log(error);
      toastMessage(
        error?.response?.data?.message || "Something went wrong.",
        "error"
      );
    }
  };

  useEffect(() => {
    if (!selectedUser) return setAllMessages([]);

    const unsubscribe = onSnapshot(
      query(collection(db, selectedUser?._id), orderBy("timestamp", "desc")),
      (snapshot) => {
        console.log(snapshot.docs);

        let messages = [];

        snapshot.docs.map((doc) => {
          messages.push(doc.data());
        });
        setAllMessages(messages);
      }
    );

    return () => {
      unsubscribe();
    };
  }, [selectedUser]);

  useEffect(() => {
    updateUserNewMessage();
  }, [selectedUser]);

  const updateUserNewMessage = async () => {
    if (!selectedUser) return;

    try {
      const userRef = collection(db, "users");
      const userQuery = query(
        userRef,
        where("userId", "==", selectedUser?._id)
      );
      const userSnapshot = await getDocs(userQuery);

      if (userSnapshot.empty) {
        // User not found, add new user
        await addDoc(userRef, {
          userId: selectedUser?._id,
          newMessage: false,
        });
      } else {
        // User found, update user
        const userDoc = userSnapshot.docs[0];
        await updateDoc(userDoc.ref, {
          newMessage: false,
        });
      }
    } catch (error) {
      console.log(error);
      toastMessage("Failed to update user's new message status", "error");
    }
  };

  console.log(allMessages);
  return (
    <>
      {selectedUser && (
        <>
          <div className="flex-1 flex flex-col h-[70vh]">
            <div className="flex sm:items-center justify-between border-b-2 border-gray-200">
              <div className="relative flex items-center space-x-4">
                <div className="relative">
                  <span className="absolute text-green-500 right-0 bottom-0">
                    <svg width={20} height={20}>
                      <circle cx={8} cy={8} r={8} fill="currentColor" />
                    </svg>
                  </span>
                  <Avatar sx={{ bgcolor: "#719FE5" }}>
                    {" "}
                    {selectedUser?.name?.charAt(0).toUpperCase()}
                  </Avatar>
                </div>
                <div className="flex flex-col leading-tight">
                  <div className="text-2xl mt-1 flex items-center">
                    <span className="text-gray-700 mr-3">
                      {selectedUser?.name}
                    </span>
                  </div>
                  <span className="text-lg text-gray-600">
                    {selectedUser?.email}
                  </span>
                </div>
              </div>
            </div>
            <div
              id="messages"
              className="flex min-h-[50vh] w-full flex-col-reverse gap-2 space-y-4 p-3 overflow-y-auto scrollbar-thumb-blue scrollbar-thumb-rounded scrollbar-track-blue-lighter scrollbar-w-2 scrolling-touch"
            >
              {allMessages?.map((message: any) => {
                if (message?.sender !== "admin") {
                  return (
                    <div className="chat-message">
                      <div className="flex items-end">
                        <div className="flex flex-col space-y-2 gap-2 text-xs max-w-xs mx-2 order-2 items-start">
                          <div>
                            <span className="px-4 py-2 rounded-lg inline-block rounded-bl-none bg-gray-300 text-gray-600">
                              {message?.message}
                            </span>
                          </div>
                        </div>
                        <Avatar
                          sx={{
                            bgcolor: "#719FE5",
                            width: 28,
                            height: 28,
                            fontSize: 12,
                          }}
                        >
                          {" "}
                          A
                        </Avatar>
                      </div>
                    </div>
                  );
                } else if (message?.sender === "admin") {
                  return (
                    <div className="chat-message">
                      <div className="flex items-end justify-end">
                        <div className="flex flex-col space-y-2 text-xs max-w-xs mx-2 order-1 items-end">
                          <div>
                            <span className="px-4 py-2 rounded-lg inline-block rounded-br-none bg-blue-600 text-white ">
                              {message?.message}
                            </span>
                          </div>
                        </div>
                        <div className="order-2">
                          <Avatar
                            sx={{
                              bgcolor: "#719FE5",
                              width: 28,
                              height: 28,
                              fontSize: 12,
                            }}
                          >
                            {" "}
                            {selectedUser?.name?.charAt(0).toUpperCase()}
                          </Avatar>
                        </div>
                      </div>
                    </div>
                  );
                }
              })}
            </div>
            <form onSubmit={sendMessage}>
              <div className="border-t-2 border-gray-200 px-4 pt-4 mb-2 sm:mb-0">
                <div className="relative flex">
                  <input
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    type="text"
                    placeholder="Write your message!"
                    className="w-full focus:outline-none focus:placeholder-gray-400 text-gray-600 placeholder-gray-600 pl-2 bg-gray-200 rounded-md py-3"
                  />
                  <div className="absolute right-0 items-center inset-y-0 hidden sm:flex">
                    <button
                      type="submit"
                      className="inline-flex items-center justify-center rounded-lg px-4 py-3 transition duration-500 ease-in-out text-white bg-blue-500 hover:bg-blue-400 focus:outline-none"
                    >
                      <span className="font-bold">Send</span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        className="h-6 w-6 ml-2 transform rotate-90"
                      >
                        <path d="M10.894 2.553a1 1 0 00-1.788 0l-7 14a1 1 0 001.169 1.409l5-1.429A1 1 0 009 15.571V11a1 1 0 112 0v4.571a1 1 0 00.725.962l5 1.428a1 1 0 001.17-1.408l-7-14z" />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <style
            dangerouslySetInnerHTML={{
              __html:
                "\n.scrollbar-w-2::-webkit-scrollbar {\n  width: 0.25rem;\n  height: 0.25rem;\n}\n\n.scrollbar-track-blue-lighter::-webkit-scrollbar-track {\n  --bg-opacity: 1;\n  background-color: #f7fafc;\n  background-color: rgba(247, 250, 252, var(--bg-opacity));\n}\n\n.scrollbar-thumb-blue::-webkit-scrollbar-thumb {\n  --bg-opacity: 1;\n  background-color: #edf2f7;\n  background-color: rgba(237, 242, 247, var(--bg-opacity));\n}\n\n.scrollbar-thumb-rounded::-webkit-scrollbar-thumb {\n  border-radius: 0.25rem;\n}\n",
            }}
          />
        </>
      )}
    </>
  );
};

export default UserChat;
