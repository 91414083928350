import React, { useEffect } from "react";
import "./App.css";
import {
  Routes,
  Route,
  useNavigate,
  Navigate,
  useLocation,
  useSearchParams,
} from "react-router-dom";
import { Toaster } from "react-hot-toast";
import Login from "./components/Login/Login";
import Dashboard from "./components/Admin/Dashboard/Dashboard";
import ForgotPassword from "./components/Login/ForgotPassword";
import VerifyOtp from "./components/Login/VerifyOtp";
import NewPassword from "./components/Login/NewPassword";
import SalesPerformance from "./components/SalesPerformance/SalesPerformance";
import RolesTable from "./components/Roles/RolesTable";
import AddUser from "./components/Roles/AddUser";
import AccountManagement from "./components/AccountManagement/AccountManagement";
import AddShippingAddress from "./components/AccountManagement/AddShippingAddress";
import MyContracts from "./components/MyContracts/MyContracts";
import ManageFiles from "./components/ManageFiles/ManageFiles";
import Resources from "./components/Resources/Resources";
import NewResource from "./components/Resources/NewResource";
import Payment from "./components/Payments/Payment";
import NewPayment from "./components/Payments/NewPayment";
import Appointment from "./components/Appointment/Appointment";
import Communication from "./components/Communication/Communication";
import SupportTicket from "./components/Communication/SupportTicket";
import { Support } from "@mui/icons-material";
import OrderManagement from "./components/OrderManagement/OrderManagement";
import PlaceOrder from "./components/OrderManagement/PlaceOrder";
import Contract from "./components/ContractsAdm/Contract";
import Materials from "./components/Materials/Materials";
import Compliance from "./components/Compliance/Compliance";
import RoleManagement from "./components/RoleManagement/RoleManagement";
import NewFile from "./components/ManageFiles/NewFile";
import { useAppSelector } from "./store/hooks";
import PageNotFound from "./components/Layout/PageNotFound";
import DistributorsTable from "./components/Admin/Distributors/DistributorsTable";
import MaterialTable from "./components/Admin/MaterialMaster/MaterialTable";
import QuantityTable from "./components/Admin/MaterialMaster/QuantityTable";
import UsersTable from "./components/Admin/Users/UsersTable";
import CreateDistributor from "./components/Admin/Distributors/CreateDistributor";
import EditDistributor from "./components/Admin/Distributors/EditDistributor";
import CategoryTable from "./components/Admin/ProductCategory/CategoryTable";
import CreateCategory from "./components/Admin/ProductCategory/CreateCategory";
import CreateProduct from "./components/Admin/Products/CreateProduct";
import ProductsTable from "./components/Admin/Products/ProductsTable";
import OrdersTable from "./components/Admin/Orders/OrdersTable";
import EditProduct from "./components/Admin/Products/EditProduct";
import GenerateOrder from "./components/Admin/Orders/GenerateOrder";
import CampaignsTable from "./components/Admin/Campaigns/CampaignsTable";
import CreateCampaign from "./components/Admin/Campaigns/CreateCampaign";
import AdminChat from "./components/Admin/AdminChat/AdminChat";
import UserChat from "./components/UserChat/UserChat";
import TicketsTable from "./components/Admin/Support/TicketsTable";
import AppointmentsTable from "./components/Admin/Appointments/AppointmentsTable";
import ContractsTable from "./components/Admin/Contracts/ContractsTable";
import CreateContract from "./components/Admin/Contracts/CreateContract";
import PaymentsTable from "./components/Admin/Payments/PaymentsTable";
import CreatePayment from "./components/Admin/Payments/CreatePayment";
import ProductsPage from "./components/BuyProduct/ProductsPage";
import ProductDetail from "./components/BuyProduct/ProductDetail";
import RoleTable from "./components/Admin/Roles/RolesTable";

function App() {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const { user } = useAppSelector((state) => state.user);

  useEffect(() => {
    if (!localStorage.getItem("token") && !searchParams.get("token")) {
      navigate("/login");
    }
  }, []);

  return (
    <div className="w-full">
      <Toaster />
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/verify-otp" element={<VerifyOtp />} />
        <Route path="/reset-password" element={<NewPassword />} />
        <Route
          path="/"
          element={
            <Navigate
              to={
                !localStorage.getItem("token")
                  ? "/login"
                  : user?.createdByRole === "Admin"
                  ? "/admin/dashboard"
                  : "/distributor/dashboard"
              }
            />
          }
        />
        <Route path="/distributor/dashboard" element={<Dashboard />} />
        <Route
          path="/distributor/sales-performance"
          element={<SalesPerformance />}
        />
        <Route path="/distributor/roles" element={<RolesTable />} />
        <Route path="/distributor/add-user" element={<AddUser />} />
        <Route
          path="/distributor/account-management"
          element={<AccountManagement />}
        />
        <Route
          path="/distributor/add-shipping-address"
          element={<AddShippingAddress />}
        />
        <Route path="/distributor/my-contracts" element={<MyContracts />} />
        <Route path="/distributor/manage-files" element={<ManageFiles />} />
        <Route path="/distributor/resources" element={<Resources />} />
        <Route path="/distributor/new-resource" element={<NewResource />} />
        <Route path="/distributor/payments" element={<Payment />} />
        <Route path="/distributor/new-payment" element={<NewPayment />} />
        <Route
          path="/distributor/appointment-management"
          element={<Appointment />}
        />
        <Route path="/distributor/communication" element={<Communication />} />
        <Route path="/distributor/support-ticket" element={<SupportTicket />} />
        <Route
          path="/distributor/order-management"
          element={<OrderManagement />}
        />
        <Route path="/distributor/place-order" element={<PlaceOrder />} />
        <Route path="/distributor/contracts-adm" element={<Contract />} />
        <Route path="/distributor/admin-chat" element={<UserChat />} />
        <Route path="/distributor/buy-products" element={<ProductsPage />} />
        <Route
          path="/distributor/product/:productId"
          element={<ProductDetail />}
        />
        {/* Admin Routes */}

        <Route path="/admin/dashboard" element={<Dashboard />} />
        <Route path="/admin/material-master" element={<MaterialTable />} />
        <Route path="/admin/material-quantity" element={<QuantityTable />} />
        <Route path="/admin/roles" element={<RoleTable />} />
        <Route path="/admin/users" element={<UsersTable />} />
        <Route path="/admin/all-distributors" element={<DistributorsTable />} />
        <Route
          path="/admin/create-distributor"
          element={<CreateDistributor />}
        />
        <Route
          path="/admin/edit-distributor/:userId"
          element={<EditDistributor />}
        />
        <Route path="/admin/product-category" element={<CategoryTable />} />
        {/* <Route path="/admin/create-category" element={<CreateCategory />} /> */}
        <Route path="/admin/create-category/:id?" element={<CreateCategory />} />
        <Route path="/admin/create-product" element={<CreateProduct />} />
        <Route path="/admin/display-product" element={<ProductsTable />} />
        <Route
          path="/admin/edit-product/:productId"
          element={<EditProduct />}
        />
        <Route path="/admin/purchase-order" element={<OrdersTable />} />
        <Route path="/admin/generate-order" element={<GenerateOrder />} />
        <Route path="/admin/marketing-campaigns" element={<CampaignsTable />} />
        <Route path="/admin/create-campaign" element={<CreateCampaign />} />
        <Route path="/admin/chat" element={<AdminChat />} />
        <Route path="/admin/support-tickets" element={<TicketsTable />} />
        <Route path="/admin/appointments" element={<AppointmentsTable />} />
        <Route path="/admin/contracts" element={<ContractsTable />} />
        <Route path="/admin/create-contract" element={<CreateContract />} />
        <Route path="/admin/payments" element={<PaymentsTable />} />
        <Route path="/admin/create-payment" element={<CreatePayment />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </div>
  );
}

export default App;
