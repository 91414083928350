import React, { useState, useEffect } from "react";
import Sidebar from "../../Layout/Sidebar";
import Loading from "react-fullscreen-loading";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { useNavigate } from "react-router-dom";
import { callAxios } from "../../../utils/axios";
import { toastMessage } from "../../../utils/toast";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import {
  FormContainer,
  TextFieldElement,
  SelectElement,
} from "react-hook-form-mui";
import { useTranslation } from "react-i18next";

const CustomTableHeadCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: "#719FE5", // Customize cell background color
  fontWeight: "bold", // Customize cell text styling
  fontFamily: "Poppins",
  fontSize: "16px",
  lineHeight: "24px",
  borderBottom: "none",
  color: "#fff",
}));
const CustomTableBodyCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: "#F4F4F4", // Customize cell background color
  fontFamily: "Poppins",
  fontSize: "16px",
  lineHeight: "24px",
  borderBottom: "none",
}));

const CustomTableHeadRow = styled(TableRow)(({ theme }) => ({
  "&:hover": {
    backgroundColor: "#E0E0E0",
  },
  border: "none",
}));
const CustomTableBodyRow = styled(TableRow)(({ theme }) => ({
  marginBottom: "10px",
}));

type FormProps = {
  materialId: string;
  name: string;
  measurementUnit: string;
};

const MaterialTable = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState(false);
  const [materials, setMaterials] = useState([]);
  const [isEditing, setIsEditing] = useState(false); // State to manage edit mode
  const [editMaterialId, setEditMaterialId] = useState<string | null>(null); // Store the materialId being edited

  const [inputValues, setInputValues] = useState<FormProps>({
    materialId: "",
    name: "",
    measurementUnit: "",
  });

  const defaultValues: FormProps = {
    materialId: "",
    name: "",
    measurementUnit: "",
  };

  const deleteMaterial = async (id: string) => {
    try {
      setLoading(true);
      await callAxios("delete", `material/${id}`);
      toastMessage("Material deleted successfully", "success");
      setChecked(!checked);
    } catch (error) {
      console.log(error);
      toastMessage(
        error?.response?.data?.message || "Failed to delete material.",
        "error"
      );
    } finally {
      setLoading(false);
    }
  };

  const handleEdit = (material: any) => {
    console.log(material);
    setInputValues({
      materialId: material.materialId,
      name: material.name,
      measurementUnit: material.measurementUnit,
    });
    setIsEditing(true);
    setEditMaterialId(material._id);
  };

  const onSubmit = async (values: FormProps) => {
    const trimValues = {
      materialId: values.materialId.trim(),
      name: values.name.trim(),
      measurementUnit: values.measurementUnit.trim(),
    };
    if (
      !trimValues.materialId ||
      !trimValues.name ||
      !trimValues.measurementUnit
    ) {
      toastMessage("Please fill out all fields correctly.", "error");
      return;
    }

    try {
      setLoading(true);

      if (isEditing && editMaterialId) {
        await callAxios("put", `material/update/${editMaterialId}`, trimValues);
        toastMessage("Material updated successfully", "success");
      } else {
        await callAxios("post", "material/createMaterial", trimValues);
        toastMessage("Material created successfully", "success");
      }

      setLoading(false);
      setInputValues(defaultValues);
      setIsEditing(false);
      setChecked(!checked);
    } catch (error) {
      console.log(error);
      setLoading(false);
      toastMessage(
        error?.response?.data?.message || "Something went wrong.",
        "error"
      );
    }
  };

  const getAllMaterials = async () => {
    try {
      setLoading(true);
      const { data } = await callAxios("get", "material/getAllMaterial");
      console.log(data);
      setMaterials(data?.materials);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      toastMessage(
        error?.response?.data?.message || "Something went wrong.",
        "error"
      );
    }
  };

  useEffect(() => {
    getAllMaterials();
  }, [checked]);

  return (
    <>
      <Loading loading={loading} background="#fff" loaderColor="#5046E5" />
      <Sidebar>
        <div className="py-6 px-4 flex flex-col gap-8">
          <div className="w-full flex justify-between items-center">
            <div className="flex flex-col gap-2">
              <h1 className="text-[#23282D] poppins text-3xl font-semibold tracking-tight ">
                {t("adminRoute.materialPage.title")}
              </h1>
              <p className="inter tracking-tight text-sm color-[#23282D]">
                {t("adminRoute.materialPage.description")}
              </p>
            </div>
          </div>
          <div className="w-full  flex flex-col gap-6 ">
            <FormContainer values={inputValues} onSuccess={onSubmit}>
              <div className="w-full grid grid-cols-4 gap-12 items-end ">
                <div className="w-full flex flex-col gap-1">
                  <p className="poppins text-md font-semibold">
                    {t("adminRoute.materialPage.formLabels.materialId")}
                    <span className="text-red-500">*</span>
                  </p>
                  <TextFieldElement
                    name="materialId"
                    required
                    variant="standard"
                    fullWidth
                    value={inputValues.materialId}
                    onChange={(e) =>
                      setInputValues({
                        ...inputValues,
                        materialId: e.target.value,
                      })
                    }
                  />
                </div>
                <div className="w-full flex flex-col gap-1">
                  <p className="poppins text-md font-semibold">
                    {t("adminRoute.materialPage.formLabels.materialName")}
                    <span className="text-red-500">*</span>
                  </p>
                  <TextFieldElement
                    name="name"
                    required
                    variant="standard"
                    fullWidth
                    value={inputValues.name}
                    onChange={(e) =>
                      setInputValues({
                        ...inputValues,
                        name: e.target.value,
                      })
                    }
                  />
                </div>
                <div className="w-full flex flex-col gap-1">
                  <p className="poppins text-md font-semibold">
                    {t("adminRoute.materialPage.formLabels.measurementUnit")}
                    <span className="text-red-500">*</span>
                  </p>
                  <SelectElement
                    name="measurementUnit"
                    required
                    variant="standard"
                    fullWidth
                    options={[
                      { label: "KG", id: "KG" },
                      { label: "Litre", id: "Litre" },
                      { label: "Pcs", id: "Pcs" },
                    ]}
                    value={inputValues.measurementUnit}
                    onChange={(e) =>
                      setInputValues({
                        ...inputValues,
                        measurementUnit: e,
                      })
                    }
                  />
                </div>
                <button
                  className="rounded bg-primary poppins text-white w-40 h-8 flex items-center justify-center"
                  type="submit"
                >
                  {isEditing
                    ? t("adminRoute.materialPage.formUpdateButton")
                    : t("adminRoute.materialPage.formCreateButton")}
                </button>
              </div>
            </FormContainer>
          </div>
          <div className="w-full bg-white p-3 rounded shadow">
            <Table
              sx={{
                borderSpacing: "0 15px !important",
                borderCollapse: "separate !important",
              }}
            >
              <TableHead>
                <CustomTableHeadRow>
                  <CustomTableHeadCell>
                    {t("adminRoute.materialPage.tableHeaders.materialId")}
                  </CustomTableHeadCell>
                  <CustomTableHeadCell>
                    {t("adminRoute.materialPage.tableHeaders.materialName")}
                  </CustomTableHeadCell>
                  <CustomTableHeadCell>
                    {t("adminRoute.materialPage.tableHeaders.measurementUnit")}
                  </CustomTableHeadCell>
                  <CustomTableHeadCell></CustomTableHeadCell>
                  <CustomTableHeadCell></CustomTableHeadCell>
                </CustomTableHeadRow>
              </TableHead>
              <TableBody>
                {materials.map((material: any) => (
                  <CustomTableBodyRow key={material?._id}>
                    <CustomTableBodyCell
                      sx={{
                        borderTopLeftRadius: "10px",
                        borderBottomLeftRadius: "10px",
                      }}
                    >
                      {material.materialId}
                    </CustomTableBodyCell>
                    <CustomTableBodyCell>{material?.name}</CustomTableBodyCell>
                    <CustomTableBodyCell>
                      {material?.measurementUnit}
                    </CustomTableBodyCell>
                    <CustomTableBodyCell
                      sx={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <button onClick={() => handleEdit(material)}>
                        <EditIcon
                          sx={{
                            color: "primary.main",
                            cursor: "pointer",
                          }}
                        />
                      </button>
                      <button onClick={() => deleteMaterial(material?._id)}>
                        <DeleteIcon sx={{ color: "red", cursor: "pointer" }} />
                      </button>
                    </CustomTableBodyCell>
                  </CustomTableBodyRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </div>
      </Sidebar>
    </>
  );
};

export default MaterialTable;
