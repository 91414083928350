import React, { useState } from "react";
import { IconButton, InputAdornment } from "@mui/material";
import { VisibilityOff, Visibility } from "@mui/icons-material";
import axios from "axios";
import { backendUrl } from "../../utils/axios";
import { FormContainer, TextFieldElement } from "react-hook-form-mui";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toastMessage } from "../../utils/toast";
import Loading from "react-fullscreen-loading";

type FormProps = {
  password: string;
  confirmPassword: string;
};

const Login = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [showPassword, setShowPassword] = React.useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [values, setValues] = useState<FormProps>();
  const [loading, setLoading] = useState(false);
  const [token, setToken] = useState(searchParams.get("token") || "");
  const [errors, setErrors] = useState({
    password: false,
    confirmPassword: false,
  });
  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };
  const handleMouseDownConfirmPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };
  const onSubmit = async (values: FormProps) => {
    console.log(values);
    try {
      setLoading(true);
      const { data } = await axios.post(
        `${backendUrl}/auth/createNewPassword`,
        {
          password: values.password,
          token,
        }
      );
      console.log(data);

      setLoading(false);
      toastMessage(data?.message, "success");

      navigate(`/login`);
    } catch (error) {
      console.log(error);
      setLoading(false);
      toastMessage(
        error?.response?.data?.message || "Something went wrong.",
        "error"
      );
    }
  };

  const defaultValues: FormProps = { password: "", confirmPassword: "" };

  //   const confirmPasswordBlurHandler = () => {
  //     console.log(values?.password, values?.confirmPassword);

  //     if (values?.password !== values?.confirmPassword) {
  //       setErrors({
  //         ...errors,
  //         confirmPassword: true,
  //       });
  //     } else {
  //       setErrors({
  //         ...errors,
  //         confirmPassword: false,
  //       });
  //     }
  //   };

  return (
    <>
      <Loading loading={loading} background="#fff" loaderColor="#5046E5" />
      <div className="w-full grid grid-cols-12 h-full">
        <div className="flex col-span-7 w-full">
          <img
            src="/assets/Images/login-bg.png"
            className="w-full min-h-[90vh] h-[100vh]"
          />
        </div>
        <div className="w-full bg-secondary items-center justify-center col-span-5 flex ">
          <FormContainer defaultValues={defaultValues} onSuccess={onSubmit}>
            <div className="w-96 flex flex-col gap-8 ">
              <div className="flex flex-col gap-3">
                <h2 className="poppins text-2xl font-bold leading-8 tracking-tight ">
                  Create New Password
                </h2>
                <p className="poppins text-[#8692A6] leading-6 ">
                  Your password must be different from your previous passwords
                </p>
              </div>
              <div className="flex flex-col gap-4">
                <div className="flex flex-col gap-3">
                  <label
                    className="poppins text-[#0196D0] text-lg font-medium "
                    htmlFor="email"
                  >
                    New Password
                  </label>
                  <TextFieldElement
                    name="password"
                    size="small"
                    fullWidth
                    type={showPassword ? "text" : "password"}
                    required
                    validation={{
                      validate: (value) => {
                        if (/\s/.test(value)) {
                          return "Password cannot contain spaces";
                        }
                        console.log(value);
                        let regex = /^(?=.*[0-9])(?=.*[@#$%^&+=!])(.{8,})$/;
                        if (regex.test(value)) {
                          return true;
                        } else {
                          return "Password must contain atleast 8 characters and a special character and a number (0-9) ";
                        }
                      },
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => setShowPassword(!showPassword)}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
                <div className="flex flex-col gap-3">
                  <label
                    className="poppins text-[#0196D0] text-lg font-medium "
                    htmlFor="email"
                  >
                    Confirm Password
                  </label>
                  <TextFieldElement
                    name="confirmPassword"
                    size="small"
                    fullWidth
                    type={showConfirmPassword ? "text" : "password"}
                    required
                    validation={{
                      validate: (
                        value,
                        values: {
                          password: string;
                          confirmPassword: string;
                        }
                      ) => {
                        console.log(values);
                        if (/\s/.test(value)) {
                          return "Password cannot contain spaces";
                        }
                        if (value === values?.password) {
                          console.log("Password Matched");

                          return true;
                        } else {
                          return "Password does not match";
                        }
                      },
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() =>
                              setShowConfirmPassword(!showConfirmPassword)
                            }
                            onMouseDown={handleMouseDownConfirmPassword}
                            edge="end"
                          >
                            {showConfirmPassword ? (
                              <VisibilityOff />
                            ) : (
                              <Visibility />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    error={errors.confirmPassword}
                    // onBlur={confirmPasswordBlurHandler}
                    // helperText={
                    //   errors.confirmPassword && "Password does not match"
                    // }
                  />
                </div>
              </div>
              <div className="flex flex-col gap-4">
                <button
                  type="submit"
                  className="bg-[#0196D0] rounded-lg w-full py-3 text-[#F8F8F8] poppins text-lg font-semibold  "
                >
                  Reset Password
                </button>
              </div>
              <div className="w-full mt-4 flex items-center justify-center">
                <p className="poppins font-medium text-[#696F79] cursor-pointer ">
                  Trouble login ?{" "}
                  <span className="text-[#EDAA62]">Raise a ticket</span>
                </p>
              </div>
            </div>
          </FormContainer>
        </div>
      </div>
    </>
  );
};

export default Login;
