import React, { useState, useEffect } from "react";
import Sidebar from "../Layout/Sidebar";
import { useNavigate } from "react-router-dom";
import { callAxios } from "../../utils/axios";
import Loading from "react-fullscreen-loading";
import { toastMessage } from "../../utils/toast";
import { useConfirm } from "material-ui-confirm";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
const CustomTableHeadCell = styled(TableCell)(({ theme }) => ({
    backgroundColor: "#fff", // Customize cell background color
    fontWeight: "bold", // Customize cell text styling
    fontFamily: "Poppins",
    fontSize: "16px",
    lineHeight: "24px",
    borderBottom: "none",
  }));
  const CustomTableBodyCell = styled(TableCell)(({ theme }) => ({
    backgroundColor: "#F4F4F4", // Customize cell background color
    fontFamily: "Poppins",
    fontSize: "16px",
    lineHeight: "24px",
    borderBottom: "none",
  }));
  
  const CustomTableHeadRow = styled(TableRow)(({ theme }) => ({
    "&:hover": {
      backgroundColor: "#E0E0E0",
    },
    border: "none",
  }));
  const CustomTableBodyRow = styled(TableRow)(({ theme }) => ({
    marginBottom: "10px",
  }));
  
  const Communication = () => {
    const navigate = useNavigate();
    const confirm = useConfirm();
  
    const [loading, setLoading] = useState(false);
    const [checked, setChecked] = useState(false);
  
    const [commune, setcommune] = useState([
      {
        Date:"26.7.23" ,
        Ticketid:"#635476",
        Name:"James",
        Filetype: "Open",
      },
      {
        Date:"2.8.23" ,
        Ticketid:"#635476",
        Name:"Dan",
        Filetype: "Closed",
      },
      {
        Date:"26.7.23" ,
        Ticketid:"#635476",
        Name:"Kartik",
        Filetype: "Open",
      },
      {
        Date:"2.8.23" ,
        Ticketid:"#635476",
        Name:"Matt",
        Filetype: "Open",
      },
      {
        Date:"26.7.23" ,
        Ticketid:"#635476",
        Name:"Julian",
        Filetype: "Closed",
      },
      {
        Date:"2.8.23" ,
        Ticketid:"#635476",
        Name:"Dan",
        Filetype: "Closed",
      },
      {
        Date:"2.8.23" ,
        Ticketid:"#635476",
        Name:"Abhishek",
        Filetype: "Open",
      },
    ] as any);
    const handleDelete = async (id: string) => {
      confirm({
        description: "Are you sure to want to delete?",
      })
        .then(async () => {
          try {
            setLoading(true);
            const { data } = await callAxios("delete", `user/deleteUser/${id}`);
            console.log(data);
            toastMessage("User Deleted Successfully", "success");
            setLoading(false);
            setChecked(!checked);
          } catch (error) {
            console.log(error);
            setLoading(false);
            toastMessage(
              error?.response?.data?.message || "Something went wrong.",
              "error"
            );
          }
        })
        .catch(() => {
          console.log("cancelled");
        });
      };
    return (
      <>
        <Loading loading={loading} background="#fff" loaderColor="#5046E5" />
        <Sidebar>
          <div className="py-6 px-4 flex flex-col gap-8">
            <div className="w-full flex justify-between items-center">
              <div className="flex flex-col gap-2">
                <h1 className="text-[#23282D] poppins text-3xl font-semibold tracking-tight ">
                  Communication & Support
                </h1>
                <p className="inter tracking-tight text-sm color-[#23282D]">
                A dedicated space for streamlined interactions, fostering effective communication and providing robust support for a seamless user experience.
                </p>
              </div>
              <button
                className="flex gap-1 h-8 px-2 items-center justify-center bg-white   rounded-lg poppins leading-6 "
                style={{
                  boxShadow: "0px 4px 8px 0px rgba(0, 0, 0, 0.20)",
                }}
                onClick={() => navigate("/distributor/support-ticket")}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M12.1094 7V17M17.1094 12L7.10938 12"
                    stroke="#4B4B4B"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <p className="wrap break-words">New Ticket</p>
              </button>
            </div>
            <div className="w-full bg-white p-4 rounded shadow ">
              <Table
                sx={{
                  borderSpacing: "0 15px !important",
                  borderCollapse: "separate !important",
                }}
              >
                <TableHead>
                  <CustomTableHeadRow>
                    <CustomTableHeadCell>DATE</CustomTableHeadCell>
                    <CustomTableHeadCell>TICKET ID</CustomTableHeadCell>
                    <CustomTableHeadCell>NAME</CustomTableHeadCell>
                    <CustomTableHeadCell>FILE TYPE</CustomTableHeadCell>
                    <CustomTableHeadCell></CustomTableHeadCell>
                  </CustomTableHeadRow>
                </TableHead>
                <TableBody>
                  {commune.map((file: any) => {
                    return (
                      <CustomTableBodyRow
                        sx={{
                          marginBottom: "10px !important",
                        }}
                      >
                        <CustomTableBodyCell
                          sx={{
                            borderTopLeftRadius: "10px",
                            borderBottomLeftRadius: "10px",
                          }}
                        >
                          {file?.Date}
                        </CustomTableBodyCell>
                        <CustomTableBodyCell>
                          {file?.Ticketid}
                        </CustomTableBodyCell>
                        <CustomTableBodyCell>
                          {file?.Name}
                        </CustomTableBodyCell>
                        <CustomTableBodyCell
                        sx={{
                          borderTopRightRadius: "10px",
                          borderBottomRightRadius: "10px",
                        }}
                      >
                        <div className={`flex gap-2 items-center `}>
                          <div
                            className={`p-1 h-1 w-1 rounded-full ${
                              file?.Filetype === "Open"
                                ? "bg-[#93E277]"
                                : file?.Filetype === "Closed"
                                ? "bg-[#EF8787]"
                                : "bg-[#EECB98]"
                            } `}
                          ></div>
                          {file?.Filetype}
                        </div>
                      </CustomTableBodyCell>
  
                        <CustomTableHeadCell>
                          <button className="bg-[#719FE5] w-full py-1 font-light px-2 text-sm text-white poppins leading-6 rounded">
                            View
                          </button>
                        </CustomTableHeadCell>
                        <CustomTableHeadCell
                        sx={{
                          borderTopRightRadius: "10px",
                          borderBottomRightRadius: "10px",
                        }}
                      >
                        <img
                          src="/assets/Icons/table-delete.svg"
                          className="w-7 h-7 cursor-pointer"
                          onClick={() => handleDelete(file?.Ticketid)}
                        />
                      </CustomTableHeadCell>
                      </CustomTableBodyRow>
                    );
                  })}
                </TableBody>
              </Table>
            </div>
          </div>
        </Sidebar>
      </>
    );
  };
  
  export default Communication;
  