import React, { useState, useEffect } from "react";
import Sidebar from "../Layout/Sidebar";
import Loading from "react-fullscreen-loading";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { useNavigate } from "react-router-dom";
import { callAxios } from "../../utils/axios";
import { toastMessage } from "../../utils/toast";
import { useConfirm } from "material-ui-confirm";
import { MenuItem, Select, Switch, TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { InputAdornment } from "@mui/material";
import OutlinedInput from "@mui/material/OutlinedInput";
import moment from "moment";
import Pagination from "@mui/material/Pagination";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Checkbox from "@mui/material/Checkbox";
import { useTranslation } from "react-i18next";

const CustomTableHeadCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: "#fff", // Customize cell background color
  fontWeight: "bold", // Customize cell text styling
  fontFamily: "Poppins",
  fontSize: "16px",
  lineHeight: "24px",
  borderBottom: "none",
  color: "black",
}));
const CustomTableBodyCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: "#F4F4F4", // Customize cell background color
  fontFamily: "Poppins",
  fontSize: "16px",
  lineHeight: "24px",
  borderBottom: "none",
}));

const CustomTableHeadRow = styled(TableRow)(({ theme }) => ({
  "&:hover": {
    backgroundColor: "#E0E0E0",
  },
  border: "none",
}));
const CustomTableBodyRow = styled(TableRow)(({ theme }) => ({
  marginBottom: "10px",
}));

const ProductsPage = () => {
  const {t} = useTranslation()
  const navigate = useNavigate();
  const confirm = useConfirm();

  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState(false);
  const [products, setProducts] = useState([] as any);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [categories, setCategories] = useState([] as any);

  const [selectedCategories, setSelectedCategories] = useState([] as any);

  const getAllProducts = async () => {
    try {
      setLoading(true);
      const { data } = await callAxios("post", `product/getProductsByFilter`, {
        categories: selectedCategories,
        page,
      });
      console.log(data);
      setProducts(data?.products);
      setTotalPages(data?.totalPages);

      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      toastMessage(
        error?.response?.data?.message || "Something went wrong.",
        "error"
      );
    }
  };

  useEffect(() => {
    getAllProducts();
  }, [checked, page, selectedCategories]);

  const getAllCategories = async () => {
    try {
      setLoading(true);
      const { data } = await callAxios("get", "category/getAllCategories");
      console.log(data);
      setCategories(data?.categories);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      toastMessage(
        error?.response?.data?.message || "Something went wrong.",
        "error"
      );
    }
  };

  useEffect(() => {
    getAllCategories();
  }, []);

  return (
    <>
      <Loading loading={loading} background="#fff" loaderColor="#719FE5" />
      <Sidebar>
        <div className="py-6 px-4 flex flex-col gap-8">
          <div className="w-full flex justify-between items-center">
            <div className="flex flex-col gap-2">
              <h1 className="text-[#23282D] poppins text-3xl font-semibold tracking-tight ">
               {t('DistributerRoute.buyProductPage.title')}
              </h1>
            </div>
          </div>
          <div className="w-full bg-white grid grid-cols-12 gap-6 p-4 rounded shadow ">
            <div className="col-span-2 w-full flex flex-col gap-3">
              <h6 className="text-[#23282D] poppins font-medium text-base uppercase ">
              {t('DistributerRoute.buyProductPage.filters')}
              </h6>
              <div className="w-full flex flex-col gap-1">
                <Accordion disableGutters>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <p className="text-[#23282D] poppins text-sm font-normal">
                    {t('DistributerRoute.buyProductPage.category')}
                    </p>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className="w-full flex flex-col gap-2">
                      {categories?.map((category: any) => {
                        return (
                          <div className="flex gap-2 items-center">
                            <Checkbox
                              size="small"
                              onChange={(e: any) => {
                                if (e.target.checked) {
                                  setSelectedCategories([
                                    ...selectedCategories,
                                    category?._id,
                                  ]);
                                } else {
                                  setSelectedCategories([
                                    ...selectedCategories.filter(
                                      (item: any) => item !== category?._id
                                    ),
                                  ]);
                                }
                              }}
                              checked={selectedCategories?.includes(
                                category?._id
                              )}
                            />
                            <p className="text-[#23282D] poppins text-sm ">
                              {category?.name}
                            </p>
                          </div>
                        );
                      })}
                    </div>
                  </AccordionDetails>
                </Accordion>
                <hr className="" />
                {/* <Accordion disableGutters>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <p className="text-[#23282D] poppins text-sm font-normal">
                      Price
                    </p>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className="w-full flex flex-col gap-2">
                      <div className="flex gap-2 items-center">
                        <Checkbox size="small" />
                        <p className="text-[#23282D] poppins text-sm ">
                          Less than 1000
                        </p>
                      </div>
                      <div className="flex gap-2 items-center">
                        <Checkbox size="small" />
                        <p className="text-[#23282D] poppins text-sm ">
                          Greater than 1000
                        </p>
                      </div>
                    </div>
                  </AccordionDetails>
                </Accordion> */}
              </div>
            </div>
            <div className="col-span-10 w-full flex flex-col gap-3">
              <h6 className="text-[#23282D] poppins font-medium text-base uppercase ">
              {t('DistributerRoute.buyProductPage.searchResults')}
              </h6>
              <div className="w-full grid grid-cols-3  gap-6 ">
                {products?.map((product: any) => {
                  return (
                    <div className="flex flex-col gap-3 ">
                      <img
                        src={product?.image}
                        alt={product?.skuName}
                        className="h-40 w-full "
                      />
                      <h6 className="poppins text-[#23282D] text-base font-medium">
                        {product?.skuName}
                      </h6>
                      <p className="text-[#23282D] poppins text-sm ">
                        {product?.skuCode}
                      </p>
                      <p className="text-[#23282D] poppins text-base font-medium">
                        {product?.price}
                      </p>
                      <button
                        onClick={() => {
                          navigate(`/distributor/product/${product?._id}`);
                        }}
                        className="w-full bg-primary py-2 text-white poppins text-sm font-medium rounded-lg"
                      >
                           {t('DistributerRoute.buyProductPage.viewProduct')}
                      </button>
                    </div>
                  );
                })}
              </div>
              <div className="w-full flex justify-end">
                <Pagination
                  count={totalPages}
                  variant="outlined"
                  shape="rounded"
                  color="primary"
                  onChange={(e, value) => setPage(value)}
                />
              </div>
            </div>
          </div>
        </div>
      </Sidebar>
    </>
  );
};

export default ProductsPage;
