import React, { useState, useEffect } from "react";
import Sidebar from "../Layout/Sidebar";
import Loading from "react-fullscreen-loading";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { useNavigate, useParams } from "react-router-dom";
import { callAxios } from "../../utils/axios";
import { toastMessage } from "../../utils/toast";
import { useConfirm } from "material-ui-confirm";
import { MenuItem, Select, Switch, TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { InputAdornment } from "@mui/material";
import OutlinedInput from "@mui/material/OutlinedInput";
import moment from "moment";
import Pagination from "@mui/material/Pagination";
import { Add, ArrowBack, Remove } from "@mui/icons-material";
import Modal from "@mui/material/Modal";
import { Box } from "@mui/material";
import {
  FormContainer,
  TextFieldElement,
  AutocompleteElement,
  SelectElement,
  SwitchElement,
} from "react-hook-form-mui";
import { useAppSelector } from "../../store/hooks";

type FormProps = {
  address: string;
  payFromCredit: boolean;
};

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 450,
  bgcolor: "#fff",
  borderRadius: "12px",
  boxShadow: 24,
  p: 4,
};

const ProductDetail = () => {
  const navigate = useNavigate();
  const confirm = useConfirm();

  const [open, setOpen] = useState(false);
  const [addressOptions, setAddressOptions] = useState([] as any);
  const { user } = useAppSelector((state) => state.user);
  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState(false);
  const { productId } = useParams();
  const [product, setProduct] = useState({} as any);
  const [quantity, setQuantity] = useState(1);
  console.log(productId);

  const getProduct = async () => {
    try {
      setLoading(true);
      const { data } = await callAxios(
        "get",
        `product/getProductById/${productId}`
      );
      console.log(data);
      setProduct(data?.product);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toastMessage(error.message, "error");
    }
  };

  useEffect(() => {
    if (productId) {
      getProduct();
    }
  }, [productId]);

  const defaultValues: FormProps = {
    address: "",
    payFromCredit: false,
  };

  const onSubmit = async (values: FormProps) => {
    console.log(values);

    if (
      values?.payFromCredit &&
      user?.distributor?.creditLimit - user?.distributor?.usedCredit <
        product?.price * quantity
    ) {
      toastMessage("Credit Limit Exceeded", "error");
      return;
    }

    try {
      setLoading(true);
      const { data } = await callAxios("post", "order/createDistributorOrder", {
        distributor: user?._id,
        address: values?.address,
        payFromCredit: values?.payFromCredit,
        products: [
          {
            product: product?._id,
            quantity,
          },
        ],
      });
      console.log(data);
      toastMessage(
        values?.payFromCredit
          ? "Order Received Successfully"
          : "Order Received Successfully, Please pay from Payments page to Process furter.",
        "success"
      );
      setLoading(false);
      navigate("/distributor/order-management");
    } catch (error) {
      console.log(error);
      setLoading(false);
      toastMessage(
        error?.response?.data?.message || "Something went wrong.",
        "error"
      );
    }
  };

  const getAddressesByDistributor = async () => {
    try {
      setLoading(true);
      const { data } = await callAxios(
        "get",
        `address/getAddressesByDistributor/${user?._id}`
      );

      console.log(data);

      setAddressOptions(
        data?.addresses.map((item: any) => ({
          label: item?.name,
          id: item?._id,
        }))
      );

      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      toastMessage(
        error?.response?.data?.message || "Something went wrong.",
        "error"
      );
    }
  };

  useEffect(() => {
    if (user) {
      getAddressesByDistributor();
    }
  }, [user]);



  return (
    <>
      <Loading loading={loading} background="#fff" loaderColor="#719FE5" />
      <Sidebar>
        <div className="py-6 px-4 flex flex-col gap-8">
          <div className="w-full bg-white p-4 rounded shadow flex flex-col gap-6 ">
            <ArrowBack
              className="cursor-pointer"
              onClick={() => navigate("/distributor/buy-product")}
            />
            <div className="w-full grid grid-cols-12 gap-6">
              <div className="flex flex-col col-span-3 gap-6">
                <img
                  src={product?.image}
                  alt={product?.name}
                  className="w-full h-60"
                />
                <button
                  onClick={() => {
                    setOpen(true);
                  }}
                  className="w-full bg-primary py-2 text-white poppins text-sm font-medium rounded-md"
                >
                  Buy Now
                </button>
              </div>
              <div className="col-span-9 flex flex-col gap-4">
             
                <h6 className="poppins text-[#23282D] font-medium text-lg leading-5">
                  {product?.skuName}
                </h6>
                <p className="poppins text-[23282D] text-base font-light ">
                  {product?.skuCode}
                </p>
                <div className="w-full flex flex-col gap-2">
                  <p className="poppinst text-sm font-medium text-black">
                    Quantity:
                  </p>

                  <div className="flex gap-2 items-center">
                    <Remove
                      fontSize="small"
                      className="text-red-500 cursor-pointer "
                      onClick={() => {
                        if (quantity > 1) {
                          setQuantity(quantity - 1);
                        }
                      }}
                    />
                    <input
                      type="number"
                      className="w-16 border border-gray-400 rounded "
                      value={quantity}
                      onChange={(e) => {
                        setQuantity(Number(e.target.value));
                      }}
                      min={1}
                    />
                    <Add
                      fontSize="small"
                      className="text-green-500 cursor-pointer"
                      onClick={() => {
                        setQuantity(quantity + 1);
                      }}
                    />
                  </div>
                </div>
                <p className="text-[#23282D] poppins  font-medium">
                  Price :{" "}
                  <span className="text-sm font-normal">${product?.price*quantity}</span>
                </p>
                <p className="text-[#23282D] poppins  font-medium">
                  Tax :{" "}
                  <span className="text-sm font-normal">
                    ${((product?.price / 100) * product?.taxPercentage)*quantity} (
                    {product?.taxPercentage}%)
                  </span>
                </p>

                <hr className="w-20" />
                <p className="text-[#23282D] poppins  font-medium">
                  Total Price :{" "}
                  <span className="text-sm font-normal">
                    $
                    {((product?.price / 100) * product?.taxPercentage +
                      product?.price)*quantity}
                  </span>
                </p>

                <div className="w-full flex flex-col gap-2">
                  <p className="poppins text-sm font-medium text-black">
                    Description:
                  </p>
                  <p className="poppins text-sm font-light text-black">
                    {product?.description}
                  </p>
                </div>
                <div className="w-full flex flex-col gap-2">
                  <p className="poppins text-sm font-medium text-black">
                    Category:
                  </p>
                  <p className="poppins text-sm font-light text-black">
                    {product?.category?.name}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal
          open={open}
          onClose={() => setOpen(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <div className="flex flex-col gap-4  w-full">
              <h3 className="text-[#23282D] poppins text-2xl font-medium leading-9 tracking-tight">
                Select Address
              </h3>
              <FormContainer defaultValues={defaultValues} onSuccess={onSubmit}>
                <div className="w-full flex flex-col gap-4">
                  <SelectElement
                    options={addressOptions}
                    name="address"
                    required
                    fullWidth
                    label="Select Address"
                    size="small"
                  />
                  {user?.distributor?.billingType === "Bill to Credit" && (
                    <div className="flex gap-2 items-center">
                      <SwitchElement label="" name="payFromCredit" />
                      <p className="text-[#23282D] poppins text-sm ">
                        Pay with Credit (Available Credit:
                        {user?.distributor?.creditLimit -
                          user?.distributor?.usedCredit}
                        )
                      </p>
                    </div>
                  )}
                  <div className="w-full grid grid-cols-2 gap-4">
                    <button
                      className="w-full bg-primary py-2 text-white poppins text-sm font-medium rounded-md"
                      type="submit"
                    >
                      Buy Now
                    </button>
                  </div>
                </div>
              </FormContainer>
            </div>
          </Box>
        </Modal>
      </Sidebar>
    </>
  );
};

export default ProductDetail;
