import React, { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";

import {
    FormContainer,
    TextFieldElement,
    SelectElement,
} from "react-hook-form-mui";
import { toastMessage } from "../../../utils/toast";
import Loading from "react-fullscreen-loading";
import { callAxios } from "../../../utils/axios";



const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
    bgcolor: "#fff",
    borderRadius: "12px",
    boxShadow: 24,
    p: 4,
};

const ViewCampaign = ({ checked, setChecked, setOpen, existingUser }: any) => {
    const [loading, setLoading] = useState(false);
    const [roleOptions, setRoleOptions] = useState([] as any);
   // console.log("ViewCampaign", existingUser)
  



    return (
        <Box sx={style}>
            <Loading loading={loading} background="#fff" loaderColor="#719FE5" />
            <div className="flex flex-col gap-4  w-full">
                <h3 className="text-[#23282D] poppins text-2xl font-medium leading-9 tracking-tight">
                    {existingUser?.name}
                </h3>
                <p className="text-[#23282D] inter text-sm font-normal leading-5 ">
                    {existingUser?.description}   </p>
                <div className="flex flex-col gap-4 w-full">
                    <div className="flex ">
                        <img src={existingUser?.file} alt="Description" className="w-full h-60 object-cover" />

                    </div>

                </div>
            </div>
        </Box >
    );
};

export default ViewCampaign;
