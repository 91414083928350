import React, { useState, useEffect } from "react";
import Sidebar from "../Layout/Sidebar";

import { useNavigate } from "react-router-dom";
import { callAxios } from "../../utils/axios";
import Loading from "react-fullscreen-loading";
import { toastMessage } from "../../utils/toast";
import { useTranslation } from "react-i18next";

const AccountManagement = () => {

  const {t} = useTranslation()
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState(false);
  const [addresses, setAddresses] = useState([] as any);

  const getAddresses = async () => {
    setLoading(true);
    try {
      const { data } = await callAxios("get", "address/getAddresses");
      setAddresses(data.addresses);

      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      toastMessage(
        error?.response?.data?.message || "Something went wrong.",
        "error"
      );
    }
  };

  useEffect(() => {
    getAddresses();
  },[checked])

  const arr = [1, 2, 3, 4];
  const arr1 = [1, 2, 3, 4, 5, 6, 7, 8];
  return (
    <>
      <Loading loading={loading} background="#fff" loaderColor="#5046E5" />
      <Sidebar>
        <div className="py-6 px-4 flex flex-col gap-8">
          <div className="w-full flex justify-between items-center">
            <div className="flex flex-col gap-2">
              <h1 className="text-[#23282D] poppins text-3xl font-semibold tracking-tight ">
              {t('DistributerRoute.accountManagementpg.accountManagement.title')}
              </h1>
              <p className="inter tracking-tight text-sm color-[#23282D]">
              {t('DistributerRoute.accountManagementpg.accountManagement.description')}              </p>
            </div>
            <button
              className="flex gap-1 h-12 px-2 items-center justify-center bg-white   rounded-lg poppins leading-6 "
              style={{
                boxShadow: "0px 4px 8px 0px rgba(0, 0, 0, 0.20)",
              }}
              onClick={() => navigate("/distributor/add-shipping-address")}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M12.1094 7V17M17.1094 12L7.10938 12"
                  stroke="#4B4B4B"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <p className="wrap break-words"> {t('DistributerRoute.accountManagementpg.accountManagement.addShippingButton.text')} </p>
            </button>
          </div>
          <div className="flex flex-col gap-6 ">
            <h5 className="poppins text-lg font-light">{t('DistributerRoute.accountManagementpg.accountManagement.sections.shipping.title')}</h5>
            <div className="w-full grid grid-cols-4 gap-8 ">
              {addresses?.map((address) => {
                return (
                  <div className="w-full rounded-lg shadow-xl flex flex-col gap-2 bg-white py-2 px-3 ">
                    <h6 className="poppins font-light ">{address?.name}</h6>
                    <div className="flex flex-col gap-1">
                      <p className="poppins text-[#00000050] break-words  ">
                        {address?.address1}
                      </p>
                      <p className="poppins text-[#00000050] break-words">
                        {address?.address2}
                      </p>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="grid grid-cols-2 gap-12">
            <div className="w-full flex gap-6 flex-col ">
              <h5 className="poppins text-lg font-light">{t('DistributerRoute.accountManagementpg.accountManagement.sections.branches.title')}</h5>
              <div className="w-full rounded-lg shadow-lg p-6 flex flex-col gap-4 bg-white ">
                {arr1.map((item) => {
                  return (
                    <div className="w-full rounded bg-[#F4F4F4] flex justify-between py-3 px-4 ">
                      <p className="poppins font-light leading-6">Branch 1</p>

                      <p className="poppins font-light leading-6">
                        Branch Address,State,Country
                      </p>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="w-full flex flex-col justify-center gap-6  ">
              <h5 className="poppins text-lg font-light">
              {t('DistributerRoute.accountManagementpg.accountManagement.sections.paymentInformation.title')}              </h5>
              <div className="w-full rounded-lg shadow-lg px-8 py-12 flex gap-20 bg-white ">
                <div className="flex flex-col gap-6">
                  <img src="/assets/Images/credit-card.png" />
                  <img src="/assets/Images/card-types.png" />
                </div>
                <img src="/assets/Icons/plus-circled.svg" />
              </div>
            </div>
          </div>
        </div>
      </Sidebar>
    </>
  );
};

export default AccountManagement;
