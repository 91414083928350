import React, { useState, useEffect } from "react";
import Sidebar from "../../Layout/Sidebar";
import Loading from "react-fullscreen-loading";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { useNavigate } from "react-router-dom";
import { callAxios } from "../../../utils/axios";
import { toastMessage } from "../../../utils/toast";
import { MenuItem, Select, Switch, TextField } from "@mui/material";
import Modal from "@mui/material/Modal";
import AddRole from "./AddRole";
// import AddUser from "./AddUser";
import moment from "moment";
// import EditUser from "./EditUser";
// import ViewUser from "./ViewUser";
import { useTranslation } from "react-i18next";
import toast from "react-hot-toast";
import DeleteIcon from "@mui/icons-material/Delete";
import AddUser from "../Users/AddUser";
import EditRole from "../Roles/EditRole";
import ViewUser from "../Users/ViewUser";

const CustomTableHeadCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: "transparent", // Customize cell background color
  fontWeight: "bold", // Customize cell text styling
  fontFamily: "Poppins",
  fontSize: "16px",
  lineHeight: "24px",
  borderBottom: "none",
  color: "#000",
}));
const CustomTableBodyCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: "#F4F4F4", // Customize cell background color
  fontFamily: "Poppins",
  fontSize: "16px",
  lineHeight: "24px",
  borderBottom: "none",
}));

const CustomTableHeadRow = styled(TableRow)(({ theme }) => ({
  "&:hover": {
    backgroundColor: "#E0E0E0",
  },
  border: "none",
}));
const CustomTableBodyRow = styled(TableRow)(({ theme }) => ({
  marginBottom: "10px",
}));

const UsersTable = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState(false);
  const [users, setUsers] = useState([] as any);
  const [openRole, setOpenRole] = useState(false);
  const [openUser, setOpenUser] = useState(false);
  const [openView, setOpenView] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [name, setName] = useState("");
  const [selectedUser, setSelectedUser] = useState({} as any);

  const { t } = useTranslation();

  const getAllUsers = async () => {
    try {
      setLoading(true);
      const { data } = await callAxios("get", "role/getAllRoles");
      console.log(data);
      // setUsers(data?.users);
      setUsers(data?.roles.reverse());
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      toastMessage(
        error?.response?.data?.message || "Something went wrong.",
        "error"
      );
    }
  };

  const deleteUser = async (roleId: any) => {
    try {
      setLoading(true);
      const response = await callAxios("delete", `role/deleteRole/${roleId}`);
      setUsers((prevUsers: any[]) =>
        prevUsers.filter((user) => user._id !== roleId)
      );
      setLoading(false);
      console.log(response);
      toast.success(response.data.message); // Show success message
      return response.data; // Return the response data if needed
    } catch (error) {
      console.error("Error deleting user:", error);
      toast.error(error.response?.data?.message || "Failed to delete user."); // Show error message
      setLoading(false);
    }
  };

  useEffect(() => {
    getAllUsers();
  }, [checked]);

  const [filteredUsers, setFilteredUsers] = useState(users);

  useEffect(() => {
    // Filter users based on the name input
    const filtered = users.filter((user) =>
      user.name.toLowerCase().includes(name.toLowerCase())
    );
    setFilteredUsers(filtered);
  }, [name, users]);

  console.log(users);

  console.log(filteredUsers);
  return (
    <>
      <Loading loading={loading} background="#fff" loaderColor="#719FE5" />
      <Sidebar>
        <div className="py-6 px-4 flex flex-col gap-8">
          <div className="w-full flex justify-between items-center">
            <div className="flex flex-col gap-2">
              <h1 className="text-[#23282D] poppins text-3xl font-semibold tracking-tight ">
                {t("adminRoute.rolesPage.title")}
              </h1>
              <p className="inter tracking-tight text-sm color-[#23282D]">
                {t("adminRoute.usersPage.description")}{" "}
              </p>
            </div>
          
            <div className="flex gap-4">
             
              <button
                className="flex gap-1 h-16 px-2 items-center justify-center bg-white  rounded-lg poppins leading-6 "
                style={{
                  boxShadow: "0px 4px 8px 0px rgba(0, 0, 0, 0.20)",
                }}
                onClick={() => setOpenRole(true)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M12.1094 7V17M17.1094 12L7.10938 12"
                    stroke="#4B4B4B"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <p className="wrap break-words">
                  {t("adminRoute.usersPage.addNewRole")}{" "}
                </p>
              </button>
            </div>
          </div>
          <div className="w-full flex flex-col gap-3 ">
            <div className="w-full bg-white p-3 rounded shadow">
              <Table
                sx={{
                  borderSpacing: "0 15px !important",
                  borderCollapse: "separate !important",
                }}
              >
                <TableHead>
                  <CustomTableHeadRow>
                    <CustomTableHeadCell>
                      {t("adminRoute.rolesPage.tableHeaders.name")}{" "}
                    </CustomTableHeadCell>
                    <CustomTableHeadCell>
                      {t("adminRoute.rolesPage.tableHeaders.modules")}{" "}
                    </CustomTableHeadCell>
                    <CustomTableHeadCell>
                      {t("adminRoute.rolesPage.tableHeaders.createdAt")}{" "}
                    </CustomTableHeadCell>

                  </CustomTableHeadRow>
                </TableHead>
                <TableBody>
                  {filteredUsers?.map((user: any) => {
                    return (
                      <CustomTableBodyRow
                        sx={{
                          marginBottom: "10px !important",
                        }}
                        key={user?._id}
                      >
                       
                        <CustomTableBodyCell>
                          <div className="flex flex-col gap-1">
                            <p className=""> {user?.name}</p>
                          </div>
                        </CustomTableBodyCell>
                        <CustomTableBodyCell>
                          <div className="flex flex-col gap-1">
                          <p>{user?.modules?.join(', ')}</p>
                          </div>
                        </CustomTableBodyCell>
                     
                        <CustomTableBodyCell>
  <div className="flex flex-col gap-1">
    <p>{new Date(user?.createdAt).toISOString().split('T')[0]}</p>
  </div>
</CustomTableBodyCell>

                     
                       
                        
                        <CustomTableHeadCell>
                          <img
                            src="/assets/Icons/edit.svg"
                            alt="edit"
                            className="w-8 h-8 cursor-pointer"
                            onClick={() => {
                              console.log(user);
                              setSelectedUser(user);
                              setOpenEdit(true);
                            }}
                          />
                        </CustomTableHeadCell>
                        <CustomTableHeadCell>
                          <DeleteIcon
                            onClick={() => {
                              if (user?._id) {
                                deleteUser(user?._id);
                              } else {
                                toast.error("User ID is missing.");
                              }
                            }}
                            sx={{ color: "red", cursor: "pointer" }}
                          />
                        </CustomTableHeadCell>
                      </CustomTableBodyRow>
                    );
                  })}
                </TableBody>
              </Table>
            </div>
          </div>
        </div>
        <Modal
          open={openRole}
          onClose={() => setOpenRole(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <AddRole
            checked={checked}
            setChecked={setChecked}
            setOpen={setOpenRole}
          />
        </Modal>
        <Modal
          open={openUser}
          onClose={() => setOpenUser(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <AddUser
            checked={checked}
            setChecked={setChecked}
            setOpen={setOpenUser}
          />
        </Modal>
        <Modal
          open={openEdit}
          onClose={() => setOpenEdit(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <EditRole
            checked={checked}
            setChecked={setChecked}
            setOpen={setOpenEdit}
            existingUser={selectedUser}
          />
        </Modal>
        <Modal
          open={openView}
          onClose={() => setOpenView(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <ViewUser
            checked={checked}
            setChecked={setChecked}
            setOpen={setOpenView}
            existingUser={selectedUser}
          />
        </Modal>
      </Sidebar>
    </>
  );
};

export default UsersTable;
