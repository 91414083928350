export const countries = [
    { code: 'AD', label: 'Andorra', id: '376' },
    {
      code: 'AE',
      label: 'United Arab Emirates',
      id: '971',
    },
    { code: 'AF', label: 'Afghanistan', id: '93' },
    {
      code: 'AG',
      label: 'Antigua and Barbuda',
      id: '1-268',
    },
    { code: 'AI', label: 'Anguilla', id: '1-264' },
    { code: 'AL', label: 'Albania', id: '355' },
    { code: 'AM', label: 'Armenia', id: '374' },
    { code: 'AO', label: 'Angola', id: '244' },
    { code: 'AQ', label: 'Antarctica', id: '672' },
    { code: 'AR', label: 'Argentina', id: '54' },
    { code: 'AS', label: 'American Samoa', id: '1-684' },
    { code: 'AT', label: 'Austria', id: '43' },
    {
      code: 'AU',
      label: 'Australia',
      id: '61',
      suggested: true,
    },
    { code: 'AW', label: 'Aruba', id: '297' },
    { code: 'AX', label: 'Alland Islands', id: '358' },
    { code: 'AZ', label: 'Azerbaijan', id: '994' },
    {
      code: 'BA',
      label: 'Bosnia and Herzegovina',
      id: '387',
    },
    { code: 'BB', label: 'Barbados', id: '1-246' },
    { code: 'BD', label: 'Bangladesh', id: '880' },
    { code: 'BE', label: 'Belgium', id: '32' },
    { code: 'BF', label: 'Burkina Faso', id: '226' },
    { code: 'BG', label: 'Bulgaria', id: '359' },
    { code: 'BH', label: 'Bahrain', id: '973' },
    { code: 'BI', label: 'Burundi', id: '257' },
    { code: 'BJ', label: 'Benin', id: '229' },
    { code: 'BL', label: 'Saint Barthelemy', id: '590' },
    { code: 'BM', label: 'Bermuda', id: '1-441' },
    { code: 'BN', label: 'Brunei Darussalam', id: '673' },
    { code: 'BO', label: 'Bolivia', id: '591' },
    { code: 'BR', label: 'Brazil', id: '55' },
    { code: 'BS', label: 'Bahamas', id: '1-242' },
    { code: 'BT', label: 'Bhutan', id: '975' },
    { code: 'BV', label: 'Bouvet Island', id: '47' },
    { code: 'BW', label: 'Botswana', id: '267' },
    { code: 'BY', label: 'Belarus', id: '375' },
    { code: 'BZ', label: 'Belize', id: '501' },
    {
      code: 'CA',
      label: 'Canada',
      id: '1',
      suggested: true,
    },
    {
      code: 'CC',
      label: 'Cocos (Keeling) Islands',
      id: '61',
    },
    {
      code: 'CD',
      label: 'Congo, Democratic Republic of the',
      id: '243',
    },
    {
      code: 'CF',
      label: 'Central African Republic',
      id: '236',
    },
    {
      code: 'CG',
      label: 'Congo, Republic of the',
      id: '242',
    },
    { code: 'CH', label: 'Switzerland', id: '41' },
    { code: 'CI', label: "Cote d'Ivoire", id: '225' },
    { code: 'CK', label: 'Cook Islands', id: '682' },
    { code: 'CL', label: 'Chile', id: '56' },
    { code: 'CM', label: 'Cameroon', id: '237' },
    { code: 'CN', label: 'China', id: '86' },
    { code: 'CO', label: 'Colombia', id: '57' },
    { code: 'CR', label: 'Costa Rica', id: '506' },
    { code: 'CU', label: 'Cuba', id: '53' },
    { code: 'CV', label: 'Cape Verde', id: '238' },
    { code: 'CW', label: 'Curacao', id: '599' },
    { code: 'CX', label: 'Christmas Island', id: '61' },
    { code: 'CY', label: 'Cyprus', id: '357' },
    { code: 'CZ', label: 'Czech Republic', id: '420' },
    {
      code: 'DE',
      label: 'Germany',
      id: '49',
      suggested: true,
    },
    { code: 'DJ', label: 'Djibouti', id: '253' },
    { code: 'DK', label: 'Denmark', id: '45' },
    { code: 'DM', label: 'Dominica', id: '1-767' },
    {
      code: 'DO',
      label: 'Dominican Republic',
      id: '1-809',
    },
    { code: 'DZ', label: 'Algeria', id: '213' },
    { code: 'EC', label: 'Ecuador', id: '593' },
    { code: 'EE', label: 'Estonia', id: '372' },
    { code: 'EG', label: 'Egypt', id: '20' },
    { code: 'EH', label: 'Western Sahara', id: '212' },
    { code: 'ER', label: 'Eritrea', id: '291' },
    { code: 'ES', label: 'Spain', id: '34' },
    { code: 'ET', label: 'Ethiopia', id: '251' },
    { code: 'FI', label: 'Finland', id: '358' },
    { code: 'FJ', label: 'Fiji', id: '679' },
    {
      code: 'FK',
      label: 'Falkland Islands (Malvinas)',
      id: '500',
    },
    {
      code: 'FM',
      label: 'Micronesia, Federated States of',
      id: '691',
    },
    { code: 'FO', label: 'Faroe Islands', id: '298' },
    {
      code: 'FR',
      label: 'France',
      id: '33',
      suggested: true,
    },
    { code: 'GA', label: 'Gabon', id: '241' },
    { code: 'GB', label: 'United Kingdom', id: '44' },
    { code: 'GD', label: 'Grenada', id: '1-473' },
    { code: 'GE', label: 'Georgia', id: '995' },
    { code: 'GF', label: 'French Guiana', id: '594' },
    { code: 'GG', label: 'Guernsey', id: '44' },
    { code: 'GH', label: 'Ghana', id: '233' },
    { code: 'GI', label: 'Gibraltar', id: '350' },
    { code: 'GL', label: 'Greenland', id: '299' },
    { code: 'GM', label: 'Gambia', id: '220' },
    { code: 'GN', label: 'Guinea', id: '224' },
    { code: 'GP', label: 'Guadeloupe', id: '590' },
    { code: 'GQ', label: 'Equatorial Guinea', id: '240' },
    { code: 'GR', label: 'Greece', id: '30' },
    {
      code: 'GS',
      label: 'South Georgia and the South Sandwich Islands',
      id: '500',
    },
    { code: 'GT', label: 'Guatemala', id: '502' },
    { code: 'GU', label: 'Guam', id: '1-671' },
    { code: 'GW', label: 'Guinea-Bissau', id: '245' },
    { code: 'GY', label: 'Guyana', id: '592' },
    { code: 'HK', label: 'Hong Kong', id: '852' },
    {
      code: 'HM',
      label: 'Heard Island and McDonald Islands',
      id: '672',
    },
    { code: 'HN', label: 'Honduras', id: '504' },
    { code: 'HR', label: 'Croatia', id: '385' },
    { code: 'HT', label: 'Haiti', id: '509' },
    { code: 'HU', label: 'Hungary', id: '36' },
    { code: 'ID', label: 'Indonesia', id: '62' },
    { code: 'IE', label: 'Ireland', id: '353' },
    { code: 'IL', label: 'Israel', id: '972' },
    { code: 'IM', label: 'Isle of Man', id: '44' },
    { code: 'IN', label: 'India', id: '91' },
    {
      code: 'IO',
      label: 'British Indian Ocean Territory',
      id: '246',
    },
    { code: 'IQ', label: 'Iraq', id: '964' },
    {
      code: 'IR',
      label: 'Iran, Islamic Republic of',
      id: '98',
    },
    { code: 'IS', label: 'Iceland', id: '354' },
    { code: 'IT', label: 'Italy', id: '39' },
    { code: 'JE', label: 'Jersey', id: '44' },
    { code: 'JM', label: 'Jamaica', id: '1-876' },
    { code: 'JO', label: 'Jordan', id: '962' },
    {
      code: 'JP',
      label: 'Japan',
      id: '81',
      suggested: true,
    },
    { code: 'KE', label: 'Kenya', id: '254' },
    { code: 'KG', label: 'Kyrgyzstan', id: '996' },
    { code: 'KH', label: 'Cambodia', id: '855' },
    { code: 'KI', label: 'Kiribati', id: '686' },
    { code: 'KM', label: 'Comoros', id: '269' },
    {
      code: 'KN',
      label: 'Saint Kitts and Nevis',
      id: '1-869',
    },
    {
      code: 'KP',
      label: "Korea, Democratic People's Republic of",
      id: '850',
    },
    { code: 'KR', label: 'Korea, Republic of', id: '82' },
    { code: 'KW', label: 'Kuwait', id: '965' },
    { code: 'KY', label: 'Cayman Islands', id: '1-345' },
    { code: 'KZ', label: 'Kazakhstan', id: '7' },
    {
      code: 'LA',
      label: "Lao People's Democratic Republic",
      id: '856',
    },
    { code: 'LB', label: 'Lebanon', id: '961' },
    { code: 'LC', label: 'Saint Lucia', id: '1-758' },
    { code: 'LI', label: 'Liechtenstein', id: '423' },
    { code: 'LK', label: 'Sri Lanka', id: '94' },
    { code: 'LR', label: 'Liberia', id: '231' },
    { code: 'LS', label: 'Lesotho', id: '266' },
    { code: 'LT', label: 'Lithuania', id: '370' },
    { code: 'LU', label: 'Luxembourg', id: '352' },
    { code: 'LV', label: 'Latvia', id: '371' },
    { code: 'LY', label: 'Libya', id: '218' },
    { code: 'MA', label: 'Morocco', id: '212' },
    { code: 'MC', label: 'Monaco', id: '377' },
    {
      code: 'MD',
      label: 'Moldova, Republic of',
      id: '373',
    },
    { code: 'ME', label: 'Montenegro', id: '382' },
    {
      code: 'MF',
      label: 'Saint Martin (French part)',
      id: '590',
    },
    { code: 'MG', label: 'Madagascar', id: '261' },
    { code: 'MH', label: 'Marshall Islands', id: '692' },
    {
      code: 'MK',
      label: 'Macedonia, the Former Yugoslav Republic of',
      id: '389',
    },
    { code: 'ML', label: 'Mali', id: '223' },
    { code: 'MM', label: 'Myanmar', id: '95' },
    { code: 'MN', label: 'Mongolia', id: '976' },
    { code: 'MO', label: 'Macao', id: '853' },
    {
      code: 'MP',
      label: 'Northern Mariana Islands',
      id: '1-670',
    },
    { code: 'MQ', label: 'Martinique', id: '596' },
    { code: 'MR', label: 'Mauritania', id: '222' },
    { code: 'MS', label: 'Montserrat', id: '1-664' },
    { code: 'MT', label: 'Malta', id: '356' },
    { code: 'MU', label: 'Mauritius', id: '230' },
    { code: 'MV', label: 'Maldives', id: '960' },
    { code: 'MW', label: 'Malawi', id: '265' },
    { code: 'MX', label: 'Mexico', id: '52' },
    { code: 'MY', label: 'Malaysia', id: '60' },
    { code: 'MZ', label: 'Mozambique', id: '258' },
    { code: 'NA', label: 'Namibia', id: '264' },
    { code: 'NC', label: 'New Caledonia', id: '687' },
    { code: 'NE', label: 'Niger', id: '227' },
    { code: 'NF', label: 'Norfolk Island', id: '672' },
    { code: 'NG', label: 'Nigeria', id: '234' },
    { code: 'NI', label: 'Nicaragua', id: '505' },
    { code: 'NL', label: 'Netherlands', id: '31' },
    { code: 'NO', label: 'Norway', id: '47' },
    { code: 'NP', label: 'Nepal', id: '977' },
    { code: 'NR', label: 'Nauru', id: '674' },
    { code: 'NU', label: 'Niue', id: '683' },
    { code: 'NZ', label: 'New Zealand', id: '64' },
    { code: 'OM', label: 'Oman', id: '968' },
    { code: 'PA', label: 'Panama', id: '507' },
    { code: 'PE', label: 'Peru', id: '51' },
    { code: 'PF', label: 'French Polynesia', id: '689' },
    { code: 'PG', label: 'Papua New Guinea', id: '675' },
    { code: 'PH', label: 'Philippines', id: '63' },
    { code: 'PK', label: 'Pakistan', id: '92' },
    { code: 'PL', label: 'Poland', id: '48' },
    {
      code: 'PM',
      label: 'Saint Pierre and Miquelon',
      id: '508',
    },
    { code: 'PN', label: 'Pitcairn', id: '870' },
    { code: 'PR', label: 'Puerto Rico', id: '1' },
    {
      code: 'PS',
      label: 'Palestine, State of',
      id: '970',
    },
    { code: 'PT', label: 'Portugal', id: '351' },
    { code: 'PW', label: 'Palau', id: '680' },
    { code: 'PY', label: 'Paraguay', id: '595' },
    { code: 'QA', label: 'Qatar', id: '974' },
    { code: 'RE', label: 'Reunion', id: '262' },
    { code: 'RO', label: 'Romania', id: '40' },
    { code: 'RS', label: 'Serbia', id: '381' },
    { code: 'RU', label: 'Russian Federation', id: '7' },
    { code: 'RW', label: 'Rwanda', id: '250' },
    { code: 'SA', label: 'Saudi Arabia', id: '966' },
    { code: 'SB', label: 'Solomon Islands', id: '677' },
    { code: 'SC', label: 'Seychelles', id: '248' },
    { code: 'SD', label: 'Sudan', id: '249' },
    { code: 'SE', label: 'Sweden', id: '46' },
    { code: 'SG', label: 'Singapore', id: '65' },
    { code: 'SH', label: 'Saint Helena', id: '290' },
    { code: 'SI', label: 'Slovenia', id: '386' },
    {
      code: 'SJ',
      label: 'Svalbard and Jan Mayen',
      id: '47',
    },
    { code: 'SK', label: 'Slovakia', id: '421' },
    { code: 'SL', label: 'Sierra Leone', id: '232' },
    { code: 'SM', label: 'San Marino', id: '378' },
    { code: 'SN', label: 'Senegal', id: '221' },
    { code: 'SO', label: 'Somalia', id: '252' },
    { code: 'SR', label: 'Suriname', id: '597' },
    { code: 'SS', label: 'South Sudan', id: '211' },
    {
      code: 'ST',
      label: 'Sao Tome and Principe',
      id: '239',
    },
    { code: 'SV', label: 'El Salvador', id: '503' },
    {
      code: 'SX',
      label: 'Sint Maarten (Dutch part)',
      id: '1-721',
    },
    {
      code: 'SY',
      label: 'Syrian Arab Republic',
      id: '963',
    },
    { code: 'SZ', label: 'Swaziland', id: '268' },
    {
      code: 'TC',
      label: 'Turks and Caicos Islands',
      id: '1-649',
    },
    { code: 'TD', label: 'Chad', id: '235' },
    {
      code: 'TF',
      label: 'French Southern Territories',
      id: '262',
    },
    { code: 'TG', label: 'Togo', id: '228' },
    { code: 'TH', label: 'Thailand', id: '66' },
    { code: 'TJ', label: 'Tajikistan', id: '992' },
    { code: 'TK', label: 'Tokelau', id: '690' },
    { code: 'TL', label: 'Timor-Leste', id: '670' },
    { code: 'TM', label: 'Turkmenistan', id: '993' },
    { code: 'TN', label: 'Tunisia', id: '216' },
    { code: 'TO', label: 'Tonga', id: '676' },
    { code: 'TR', label: 'Turkey', id: '90' },
    {
      code: 'TT',
      label: 'Trinidad and Tobago',
      id: '1-868',
    },
    { code: 'TV', label: 'Tuvalu', id: '688' },
    {
      code: 'TW',
      label: 'Taiwan',
      id: '886',
    },
    {
      code: 'TZ',
      label: 'United Republic of Tanzania',
      id: '255',
    },
    { code: 'UA', label: 'Ukraine', id: '380' },
    { code: 'UG', label: 'Uganda', id: '256' },
    {
      code: 'US',
      label: 'United States',
      id: '1',
      suggested: true,
    },
    { code: 'UY', label: 'Uruguay', id: '598' },
    { code: 'UZ', label: 'Uzbekistan', id: '998' },
    {
      code: 'VA',
      label: 'Holy See (Vatican City State)',
      id: '379',
    },
    {
      code: 'VC',
      label: 'Saint Vincent and the Grenadines',
      id: '1-784',
    },
    { code: 'VE', label: 'Venezuela', id: '58' },
    {
      code: 'VG',
      label: 'British Virgin Islands',
      id: '1-284',
    },
    {
      code: 'VI',
      label: 'US Virgin Islands',
      id: '1-340',
    },
    { code: 'VN', label: 'Vietnam', id: '84' },
    { code: 'VU', label: 'Vanuatu', id: '678' },
    { code: 'WF', label: 'Wallis and Futuna', id: '681' },
    { code: 'WS', label: 'Samoa', id: '685' },
    { code: 'XK', label: 'Kosovo', id: '383' },
    { code: 'YE', label: 'Yemen', id: '967' },
    { code: 'YT', label: 'Mayotte', id: '262' },
    { code: 'ZA', label: 'South Africa', id: '27' },
    { code: 'ZM', label: 'Zambia', id: '260' },
    { code: 'ZW', label: 'Zimbabwe', id: '263' },
  ];