import React, { useState } from "react";
import axios from "axios";
import { backendUrl } from "../../utils/axios";
import { FormContainer, TextFieldElement } from "react-hook-form-mui";
import { useNavigate } from "react-router-dom";
import { toastMessage } from "../../utils/toast";
import Loading from "react-fullscreen-loading";

type FormProps = {
  email: string;
};

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const onSubmit = async (values: FormProps) => {
    console.log(values);

    try {
      setLoading(true);
      const { data } = await axios.post(`${backendUrl}/auth/sendOtp`, values);
      console.log(data);
      setLoading(false);
      toastMessage(data.message, "success");

      navigate(`/verify-otp?token=${data.token}`);
    } catch (error) {
      console.log(error);
      setLoading(false);
      toastMessage(error?.response?.data?.message||"Something went wrong.", "error");
    }
  };

  const defaultValues: FormProps = { email: "" };

  return (
    <>
      <Loading loading={loading} background="#fff" loaderColor="#5046E5" />
      <div className="w-full grid grid-cols-12 h-full">
        <div className="flex col-span-7 w-full">
          <img
            src="/assets/Images/login-bg.png"
            className="w-full min-h-[90vh] h-[100vh]"
          />
        </div>
        <div className="w-full bg-secondary items-center justify-center col-span-5 flex ">
          <FormContainer defaultValues={defaultValues} onSuccess={onSubmit}>
            <div className="w-96 flex flex-col gap-8 ">
              <div className="flex flex-col gap-3">
                <h2 className="poppins text-2xl font-bold leading-8 tracking-tight ">
                  Forgot Password
                </h2>
                <p className="poppins text-[#8692A6] leading-6 ">
                  Enter your email address and we’ll send you an OTP on mail to
                  reset your password
                </p>
              </div>
              <div className="flex flex-col gap-4">
                <div className="flex flex-col gap-3">
                  <label
                    className="poppins text-[#0196D0] text-lg font-medium "
                    htmlFor="email"
                  >
                    Email Address
                  </label>
                  <TextFieldElement
                    name="email"
                    type="email"
                    variant="outlined"
                    size="small"
                    fullWidth
                    required
                  />
                </div>
              </div>
              <div className="flex flex-col gap-4">
                <button
                  type="submit"
                  className="bg-[#0196D0] rounded-lg w-full py-3 text-[#F8F8F8] poppins text-lg font-semibold  "
                >
                  Send Otp
                </button>
              </div>
            </div>
          </FormContainer>
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;
