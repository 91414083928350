import React, { useState, useEffect } from "react";
import Sidebar from "../Layout/Sidebar";
import Loading from "react-fullscreen-loading";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { useNavigate } from "react-router-dom";
import { callAxios } from "../../utils/axios";
import { toastMessage } from "../../utils/toast";
import { useConfirm } from "material-ui-confirm";
import Modal from "@mui/material/Modal";
import ViewRoles from "./ViewRoles";
import { useTranslation } from "react-i18next";

const CustomTableHeadCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: "#fff", // Customize cell background color
  fontWeight: "bold", // Customize cell text styling
  fontFamily: "Poppins",
  fontSize: "16px",
  lineHeight: "24px",
  borderBottom: "none",
}));
const CustomTableBodyCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: "#F4F4F4", // Customize cell background color
  fontFamily: "Poppins",
  fontSize: "16px",
  lineHeight: "24px",
  borderBottom: "none",
}));

const CustomTableHeadRow = styled(TableRow)(({ theme }) => ({
  "&:hover": {
    backgroundColor: "#E0E0E0",
  },
  border: "none",
}));
const CustomTableBodyRow = styled(TableRow)(({ theme }) => ({
  marginBottom: "10px",
}));

const RolesTable = () => {
const {t} = useTranslation();

  const navigate = useNavigate();
  const confirm = useConfirm();

  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState(false);
  const [users, setUsers] = useState([] as any);
  const [openView, setOpenView] = useState(false);
  const [selectedUser, setSelectedUser] = useState({} as any);

  const getAllUsers = async () => {
    try {
      setLoading(true);
      const { data } = await callAxios("get", "user/getUsers");
      console.log(data);
      setUsers(data?.users);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      toastMessage(
        error?.response?.data?.message || "Something went wrong.",
        "error"
      );
    }
  };

  useEffect(() => {
    getAllUsers();
  }, [checked]);

  const handleDelete = async (id: string) => {
    confirm({
      description: "Are you sure to want to delete?",
    })
      .then(async () => {
        try {
          setLoading(true);
          const { data } = await callAxios("delete", `user/deleteUser/${id}`);
          console.log(data);
          toastMessage("User Deleted Successfully", "success");
          setLoading(false);
          setChecked(!checked);
        } catch (error) {
          console.log(error);
          setLoading(false);
          toastMessage(
            error?.response?.data?.message || "Something went wrong.",
            "error"
          );
        }
      })
      .catch(() => {
        console.log("cancelled");
      });
  };

  return (
    <>
      <Loading loading={loading} background="#fff" loaderColor="#5046E5" />
      <Sidebar>
        <div className="py-6 px-4 flex flex-col gap-8">
          <div className="w-full flex justify-between items-center">
            <div className="flex flex-col gap-2">
              <h1 className="text-[#23282D] poppins text-3xl font-semibold tracking-tight ">
                {t('DistributerRoute.rolesPage.title')}
              </h1>
              <p className="inter tracking-tight text-sm color-[#23282D]">
              {t('DistributerRoute.rolesPage.description  ')}              </p>
            </div>
            <button
              className="flex gap-1 h-8 px-2 items-center justify-center bg-white   rounded-lg poppins leading-6 "
              style={{
                boxShadow: "0px 4px 8px 0px rgba(0, 0, 0, 0.20)",
              }}
              onClick={() => navigate("/distributor/add-user")}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M12.1094 7V17M17.1094 12L7.10938 12"
                  stroke="#4B4B4B"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <p className="wrap break-words">{t('DistributerRoute.rolesPage.addNewUserButton.text')}  </p>
            </button>
          </div>
          {/* Table */}
          <div className="w-full bg-white p-3 rounded shadow">
            <Table
              sx={{
                borderSpacing: "0 15px !important",
                borderCollapse: "separate !important",
              }}
            >
              <TableHead>
                <CustomTableHeadRow>
                  <CustomTableHeadCell>{t('DistributerRoute.rolesPage.table.headers.userId')} </CustomTableHeadCell>
                  <CustomTableHeadCell>{t('DistributerRoute.rolesPage.table.headers.name')} </CustomTableHeadCell>
                  <CustomTableHeadCell>{t('DistributerRoute.rolesPage.table.headers.email')} </CustomTableHeadCell>
                  <CustomTableHeadCell>{t('DistributerRoute.rolesPage.table.headers.role')} </CustomTableHeadCell>
                  <CustomTableHeadCell></CustomTableHeadCell>
                  <CustomTableHeadCell></CustomTableHeadCell>
                </CustomTableHeadRow>
              </TableHead>
              <TableBody>
                {users.map((user: any) => {
                  return (
                    <CustomTableBodyRow
                      sx={{
                        marginBottom: "10px !important",
                      }}
                      key={user?._id}
                    >
                      <CustomTableBodyCell
                        sx={{
                          borderTopLeftRadius: "10px",
                          borderBottomLeftRadius: "10px",
                        }}
                      >
                        P1543
                      </CustomTableBodyCell>
                      <CustomTableBodyCell>{user?.name}</CustomTableBodyCell>
                      <CustomTableBodyCell>{user?.email}</CustomTableBodyCell>
                      <CustomTableBodyCell>{user?.role}</CustomTableBodyCell>
                      <CustomTableBodyCell
                        sx={{
                          borderTopRightRadius: "10px",
                          borderBottomRightRadius: "10px",
                        }}
                      >
                        <img
                          src="/assets/Icons/table-delete.svg"
                          className="w-7 h-7 cursor-pointer"
                          onClick={() => handleDelete(user?._id)}
                        />
                      </CustomTableBodyCell>
                      <CustomTableHeadCell>
                        <button className="bg-[#719FE5] w-full py-1 font-light px-2 text-sm text-white poppins leading-6 rounded    "
                          onClick={() => {
                            setSelectedUser(user);
                            setOpenView(true);
                          }}
                        >
                          {t('DistributerRoute.rolesPage.table.viewButton.text')} 
                        </button>
                      </CustomTableHeadCell>
                    </CustomTableBodyRow>
                  );
                })}
              </TableBody>
            </Table>
          </div>
        </div>
        <Modal
          open={openView}
          onClose={() => setOpenView(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <ViewRoles
            checked={checked}
            setChecked={setChecked}
            setOpen={setOpenView}
            existingUser={selectedUser}
          />
        </Modal>
      </Sidebar>
    </>
  );
};

export default RolesTable;
