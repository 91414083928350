import React, { useState, useEffect } from "react";
import Sidebar from "../Layout/Sidebar";
import { useNavigate } from "react-router-dom";
import { callAxios } from "../../utils/axios";
import Loading from "react-fullscreen-loading";
import { toastMessage } from "../../utils/toast";
import { useConfirm } from "material-ui-confirm";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import moment from "moment";
import { useTranslation } from "react-i18next";
const CustomTableHeadCell = styled(TableCell)(({ theme }) => ({
    backgroundColor: "#fff", // Customize cell background color
    fontWeight: "bold", // Customize cell text styling
    fontFamily: "Poppins",
    fontSize: "16px",
    lineHeight: "24px",
    borderBottom: "none",
  }));
  const CustomTableBodyCell = styled(TableCell)(({ theme }) => ({
    backgroundColor: "#F4F4F4", // Customize cell background color
    fontFamily: "Poppins",
    fontSize: "16px",
    lineHeight: "24px",
    borderBottom: "none",
  }));
  
  const CustomTableHeadRow = styled(TableRow)(({ theme }) => ({
    "&:hover": {
      backgroundColor: "#E0E0E0",
    },
    border: "none",
  }));
  const CustomTableBodyRow = styled(TableRow)(({ theme }) => ({
    marginBottom: "10px",
  }));
  
  const Resources = () => {
    const {t} = useTranslation()
    const navigate = useNavigate();
    const confirm = useConfirm();
  
    const [loading, setLoading] = useState(false);
    const [checked, setChecked] = useState(false);
    const [files, setfiles] = useState([] as any);
    const getFiles = async () => {
      setLoading(true);
      try {
        const { data } = await callAxios("get", "resource/getResourceOfAdmin");
        console.log(data);
        setfiles(data?.resources);
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
        toastMessage(
          error?.response?.data?.message || "Something went wrong.",
          "error"
        );
      }
    };
  
    useEffect(() => {
      getFiles();
    }, [checked]);
  
    return (
      <>
        <Loading loading={loading} background="#fff" loaderColor="#5046E5" />
        <Sidebar>
          <div className="py-6 px-4 flex flex-col gap-8">
            <div className="w-full flex justify-between items-center">
              <div className="flex flex-col gap-2">
                <h1 className="text-[#23282D] poppins text-3xl font-semibold tracking-tight ">
                {t('DistributerRoute.resourcesPage.title')}
                </h1>
                <p className="inter tracking-tight text-sm color-[#23282D]">
                {t('DistributerRoute.resourcesPage.description')}                </p>
              </div>
         
            </div>
            <div className="w-full bg-white p-4 rounded shadow ">
              <Table
                sx={{
                  borderSpacing: "0 15px !important",
                  borderCollapse: "separate !important",
                }}
              >
                <TableHead>
                  <CustomTableHeadRow>
                    <CustomTableHeadCell>{t('DistributerRoute.resourcesPage.tableHeaders.date')}   </CustomTableHeadCell>
                    <CustomTableHeadCell>{t('DistributerRoute.resourcesPage.tableHeaders.resourceId')}</CustomTableHeadCell>
                    <CustomTableHeadCell>{t('DistributerRoute.resourcesPage.tableHeaders.fileName')}</CustomTableHeadCell>
                    <CustomTableHeadCell>{t('DistributerRoute.resourcesPage.tableHeaders.fileType')}</CustomTableHeadCell>
                    <CustomTableHeadCell></CustomTableHeadCell>
                  </CustomTableHeadRow>
                </TableHead>
                <TableBody>
                {files.map((file: any) => {
                  return (
                    <CustomTableBodyRow
                      sx={{
                        marginBottom: "10px !important",
                      }}
                    >
                      <CustomTableBodyCell
                        sx={{
                          borderTopLeftRadius: "10px",
                          borderBottomLeftRadius: "10px",
                        }}
                      >
                        {moment(file?.createdAt).format("DD.MM.YYYY")}
                      </CustomTableBodyCell>
                      <CustomTableBodyCell>{file?.fileId}</CustomTableBodyCell>
                      <CustomTableBodyCell>
                        {file?.fileName}
                      </CustomTableBodyCell>
                      <CustomTableBodyCell
                        sx={{
                          borderTopRightRadius: "10px",
                          borderBottomRightRadius: "10px",
                        }}
                      >
                        <div className={`flex gap-2 items-center uppercase `}>
                          {file?.fileType}
                        </div>
                      </CustomTableBodyCell>

                      <CustomTableHeadCell>
                        <button
                          onClick={() => {
                            const link = document.createElement("a");
                            link.href = file?.fileUrl;
                            link.download = file?.fileName;
                            link.target = "_blank";
                            link.click();
                          }}
                          className="bg-[#719FE5] w-full py-1 font-light px-2 text-sm text-white poppins leading-6 rounded"
                        >
                       {t('DistributerRoute.resourcesPage.tableActions.view')}
                        </button>
                      </CustomTableHeadCell>
                
                    </CustomTableBodyRow>
                  );
                })}
                </TableBody>
              </Table>
            </div>
          </div>
        </Sidebar>
      </>
    );
  };
  
  export default Resources;