import React, { useState, useEffect } from "react";
import Sidebar from "../../Layout/Sidebar";
import Loading from "react-fullscreen-loading";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { useNavigate } from "react-router-dom";
import { callAxios } from "../../../utils/axios";
import { toastMessage } from "../../../utils/toast";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { TextField } from "@mui/material";
import { FormContainer, TextFieldElement } from "react-hook-form-mui";
import { useTranslation } from "react-i18next";

const CustomTableHeadCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: "#719FE5", // Customize cell background color
  fontWeight: "bold", // Customize cell text styling
  fontFamily: "Poppins",
  fontSize: "16px",
  lineHeight: "24px",
  borderBottom: "none",
  color: "#fff",
}));
const CustomTableBodyCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: "#F4F4F4", // Customize cell background color
  fontFamily: "Poppins",
  fontSize: "16px",
  lineHeight: "24px",
  borderBottom: "none",
}));

const CustomTableHeadRow = styled(TableRow)(({ theme }) => ({
  "&:hover": {
    backgroundColor: "#E0E0E0",
  },
  border: "none",
}));
const CustomTableBodyRow = styled(TableRow)(({ theme }) => ({
  marginBottom: "10px",
}));

type FormProps = {
  quantity: number;
};

const QuantityTable = () => {
  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState(false);
  const [materials, setMaterials] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [selectedMaterialId, setSelectedMaterialId] = useState("");

const {t} = useTranslation()

  const getAllMaterials = async () => {
    try {
      setLoading(true);
      const { data } = await callAxios("get", "material/getAllMaterial");
      console.log(data);
      setMaterials(data?.materials);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      toastMessage(
        error?.response?.data?.message || "Something went wrong.",
        "error"
      );
    }
  };

  useEffect(() => {
    getAllMaterials();
  }, [checked]);
  const defaultValues: FormProps = {
    quantity: undefined,
  };

  const onSubmit = async (values: FormProps) => {
    try {
      setLoading(true);
      const { data } = await callAxios(
        "put",
        "material/updateMaterialQuantity/" + selectedMaterialId,
        values
      );
      console.log(data);
      setOpen(false);
      
      setChecked(!checked);
      setLoading(false);
      toastMessage("Quantity Updated Successfullly", "success");
    } catch (error) {
      console.log(error);
      setLoading(false);
      toastMessage(
        error?.response?.data?.message || "Something went wrong.",
        "error"
      );
    }
  };

  return (
    <>
      <Loading loading={loading} background="#fff" loaderColor="#5046E5" />
      <Sidebar>
        <div className="py-6 px-4 flex flex-col gap-8">
          <div className="w-full flex justify-between items-center">
            <div className="flex flex-col gap-2">
              <h1 className="text-[#23282D] poppins text-3xl font-semibold tracking-tight ">
              {t('adminRoute.materialsQuantityPage.title')}
              </h1>
              <p className="inter tracking-tight text-sm color-[#23282D]">
              {t('adminRoute.materialsQuantityPage.description')}</p>
            </div>
          </div>
          <div className="w-full bg-white p-3 rounded shadow">
            <Table
              sx={{
                borderSpacing: "0 15px !important",
                borderCollapse: "separate !important",
              }}
            >
              <TableHead>
                <CustomTableHeadRow>
                  <CustomTableHeadCell>{t('adminRoute.materialsQuantityPage.tableHeaders.materialId')} </CustomTableHeadCell>
                  <CustomTableHeadCell>{t('adminRoute.materialsQuantityPage.tableHeaders.materialName')}</CustomTableHeadCell>
                  <CustomTableHeadCell>{t('adminRoute.materialsQuantityPage.tableHeaders.measurementUnit')}</CustomTableHeadCell>
                  <CustomTableHeadCell>{t('adminRoute.materialsQuantityPage.tableHeaders.quantity')}</CustomTableHeadCell>
                  <CustomTableHeadCell></CustomTableHeadCell>
                </CustomTableHeadRow>
              </TableHead>
              <TableBody>
                {materials.map((material: any) => {
                  return (
                    <CustomTableBodyRow
                      sx={{
                        marginBottom: "10px !important",
                      }}
                      key={material?._id}
                    >
                      <CustomTableBodyCell
                        sx={{
                          borderTopLeftRadius: "10px",
                          borderBottomLeftRadius: "10px",
                        }}
                      >
                        {material.materialId}
                      </CustomTableBodyCell>
                      <CustomTableBodyCell>
                        {material?.name}
                      </CustomTableBodyCell>
                      <CustomTableBodyCell>
                      {material?.measurementUnit}
                      </CustomTableBodyCell>
                      <CustomTableBodyCell
                        sx={{
                          borderTopRightRadius: "10px",
                          borderBottomRightRadius: "10px",
                        }}
                      >
                        {material?.quantity}
                      </CustomTableBodyCell>
                      <CustomTableHeadCell
                        sx={{
                          backgroundColor: "#fff",
                        }}
                        align="center"
                      >
                        <div className="w-full flex items-center justify-center">
                          <button
                            className="rounded bg-primary poppins text-white w-40 h-8 flex items-center justify-center "
                            onClick={() => {
                              setSelectedMaterialId(material._id);
                              setOpen(true);
                            }}
                          >
                           {t('adminRoute.materialsQuantityPage.tableHeaders.addButton')}
                          </button>
                        </div>
                      </CustomTableHeadCell>
                    </CustomTableBodyRow>
                  );
                })}
              </TableBody>
            </Table>
          </div>
        </div>
        <Dialog open={open} onClose={() => setOpen(false)}>
          <DialogTitle>{t('adminRoute.materialsQuantityPage.dialog.title')}</DialogTitle>
          <DialogContent>
            <FormContainer defaultValues={defaultValues} onSuccess={onSubmit}>
              <div className="flex flex-col w-80 items-center gap-8 py-2 ">
                <TextFieldElement placeholder="Add or Subtract the Quantity" name="quantity" size="small" label={t('adminRoute.materialsQuantityPage.dialog.formLabels.quantity')} required variant="standard" fullWidth />
                <div className="w-full flex items-center justify-end">
                  <button
                    className="rounded bg-primary poppins text-white w-32 h-8 flex items-center justify-center "
                    type="submit"
                  >
                   {t('adminRoute.materialsQuantityPage.dialog.formSubmitButton')}
                  </button>
                </div>
              </div>
            </FormContainer>
          </DialogContent>
        </Dialog>
      </Sidebar>
    </>
  );
};

export default QuantityTable;
