import React, { useState, useEffect } from "react";
import { toastMessage } from "../../../utils/toast";
import Loading from "react-fullscreen-loading";
import { useNavigate } from "react-router-dom";
import Sidebar from "../../Layout/Sidebar";
import { callAxios } from "../../../utils/axios";
import ProductDetails from "./EditForm/ProductDetails";
import BatchDetails from "./EditForm/BatchDetails";
import PricingDetails from "./EditForm/PricingDetails";
import AdditionalDetails from "./EditForm/AdditionalDetails";
import { useParams } from "react-router-dom";

const EditProduct = () => {
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(1);
  const [existingData, setExistingData] = useState(null);
  const [formData, setFormData] = useState({});
  const navigate = useNavigate();
  const { productId } = useParams();
  console.log(formData);

  const getProductById = async () => {
    try {
      setLoading(true);
      const { data } = await callAxios(
        "get",
        `product/getProductById/${productId}`
      );
      console.log(data);
      setExistingData(data?.product);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      toastMessage(
        error?.response?.data?.message || "Something went wrong.",
        "error"
      );
    }
  };

  useEffect(() => {
    if (productId) {
      getProductById();
    }
  }, [productId]);
  console.log(existingData);

  const finalSubmit = async (formValues: any) => {
    const values = { ...formData, ...formValues };
    console.log(values);
    try {
      setLoading(true);

      const { data } = await callAxios("put", `product/editProduct/${productId}`, values);
      console.log(data);
      toastMessage("Product Edited Successfully", "success");
      setLoading(false);
      navigate("/admin/display-product");
    } catch (error) {
      console.log(error);
      setLoading(false);
      toastMessage(
        error?.response?.data?.message || "Something went wrong.",
        "error"
      );
    }
  };

  return (
    <>
      <Loading loading={loading} background="#fff" loaderColor="#719FE5" />
      <Sidebar>
        <div className="py-6 px-8 flex flex-col gap-8">
          <div className="w-full flex justify-between items-center">
            <div className="flex flex-col gap-2">
              <h1 className="text-[#23282D] poppins text-3xl font-semibold tracking-tight ">
                Create Product
              </h1>
              <p className="inter tracking-tight text-sm color-[#23282D]">
              Your canvas for showcasing and detailing each product, facilitating a user-friendly interface to curate and present your offerings with precision.
              </p>
            </div>
          </div>
          <div className="w-full bg-[#fff] flex flex-col p-4 gap-8 rounded ">
            <div className="w-full flex justify-between py-2 rounded-lg px-8 align-items bg-white shadow-xl ">
              <div className="flex gap-4 items-center ">
                <p
                  className={` rounded-full poppins font-bold px-3 py-1 text-sm text-white ${
                    step === 1 ? "bg-primary " : "bg-[#9E9E9E]"
                  }  `}
                >
                  1
                </p>
                <p className="poppins font-medium ">Product Details</p>
              </div>
              <div className="flex gap-4 items-center ">
                <p
                  className={` rounded-full poppins font-bold px-3 py-1 text-sm text-white ${
                    step === 2 ? "bg-primary " : "bg-[#9E9E9E]"
                  }  `}
                >
                  2
                </p>
                <p className="poppins font-medium ">Batch Details</p>
              </div>
              <div className="flex gap-4 items-center ">
                <p
                  className={` rounded-full poppins font-bold px-3 py-1 text-sm text-white ${
                    step === 3 ? "bg-primary " : "bg-[#9E9E9E]"
                  }  `}
                >
                  3
                </p>
                <p className="poppins font-medium ">Pricing Details</p>
              </div>
              <div className="flex gap-4 items-center ">
                <p
                  className={` rounded-full poppins font-bold px-3 py-1 text-sm text-white ${
                    step === 4 ? "bg-primary " : "bg-[#9E9E9E]"
                  }  `}
                >
                  4
                </p>
                <p className="poppins font-medium ">Additional Details</p>
              </div>
            </div>
            {existingData && (
              <>
                {step === 1 && (
                  <ProductDetails
                    step={step}
                    setStep={setStep}
                    formData={formData}
                    setFormData={setFormData}
                    existingData={existingData}
                  />
                )}
                {step === 2 && (
                  <BatchDetails
                    step={step}
                    setStep={setStep}
                    formData={formData}
                    setFormData={setFormData}
                    existingData={existingData}
                  />
                )}
                {step === 3 && (
                  <PricingDetails
                    step={step}
                    setStep={setStep}
                    formData={formData}
                    setFormData={setFormData}
                    existingData={existingData}
                  />
                )}
                {step === 4 && (
                  <AdditionalDetails
                    step={step}
                    setStep={setStep}
                    formData={formData}
                    setFormData={setFormData}
                    finalSubmit={finalSubmit}
                    existingData={existingData}
                  />
                )}
              </>
            )}
          </div>
        </div>
      </Sidebar>
    </>
  );
};

export default EditProduct;
