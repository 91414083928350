import React, { useState, useEffect } from "react";
import Sidebar from "../Layout/Sidebar";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { useNavigate } from "react-router-dom";
import { callAxios } from "../../utils/axios";
import Loading from "react-fullscreen-loading";
import { toastMessage } from "../../utils/toast";
import dayjs, { Dayjs } from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { Switch, TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import moment from "moment";
import { useTranslation } from "react-i18next";

const CustomTableHeadCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: "#fff", // Customize cell background color
  fontWeight: "bold", // Customize cell text styling
  fontFamily: "Poppins",
  fontSize: "16px",
  lineHeight: "24px",
  borderBottom: "none",
}));
const CustomTableBodyCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: "#F4F4F4", // Customize cell background color
  fontFamily: "Poppins",
  fontSize: "16px",
  lineHeight: "24px",
  borderBottom: "none",
}));

const CustomTableHeadRow = styled(TableRow)(({ theme }) => ({
  "&:hover": {
    backgroundColor: "#E0E0E0",
  },
  border: "none",
}));
const CustomTableBodyRow = styled(TableRow)(({ theme }) => ({
  marginBottom: "10px",
}));

const Appointment = () => {
  const {t} = useTranslation()
  const navigate = useNavigate();
  const [values, setValues] = useState({
    date: dayjs(),
    time: "10 AM - 11:30 AM",
    online: false,
    agenda: "",
  });
  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState(false);
  const [appointments, setAppointments] = useState([] as any);
  const [startDate, setStartDate] = useState(null as any);
  const [endDate, setEndDate] = useState(null as string | null);

  const slots = [
    "10 AM - 10:30 AM",
    "10:30 AM - 11:00 AM",
    "11:00 AM - 11:30 AM",
    "11:30 AM - 12:00 PM",
    "12:00 PM - 12:30 PM",
    "12:30 PM - 01:00 PM",
    "01:00 PM - 01:30 PM",
    "01:30 PM - 02:00 PM",
    "02:00 PM - 02:30 PM",
    "02:30 PM - 03:00 PM",
    "03:00 PM - 03:30 PM",
    "03:30 PM - 04:00 PM",
    "04:00 PM - 04:30 PM",
    "04:30 PM - 05:00 PM",
    "05:00 PM - 05:30 PM",
    "05:30 PM - 06:00 PM",
    "06:00 PM - 06:30 PM",
    "06:30 PM - 07:00 PM",
  ];

  const handleSubmit = async () => {
    let formData: any = { ...values };
    formData.mode = values.online ? "online" : "offline";
    formData.date = values.date.format("YYYY-MM-DD");

    try {
      setLoading(true);

      const { data } = await callAxios(
        "post",
        "appointment/createAppointment",
        formData
      );
      console.log(data);
      toastMessage(data?.message, "success");
      setValues({
        date: dayjs(),
        time: "10 AM - 11:30 AM",
        online: false,
        agenda: "",
      });
      setChecked(!checked);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      toastMessage(
        error?.response?.data?.message || "Something went wrong.",
        "error"
      );
    }
  };

  const getAppointments = async () => {
    try {
      setLoading(true);

      const { data } = await callAxios(
        "get",
        `${
          startDate && endDate
            ? `appointment/getAppointmentsOfUser?startDate=${startDate}&endDate=${endDate}`
            : `appointment/getAppointmentsOfUser`
        } `
      );
      console.log(data);
      setAppointments(data?.appointments);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      toastMessage(
        error?.response?.data?.message || "Something went wrong.",
        "error"
      );
    }
  };

  useEffect(() => {
    getAppointments();
  }, [checked]);

  return (
    <>
      <Loading loading={loading} background="#fff" loaderColor="#5046E5" />
      <Sidebar>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <div className="py-12 px-12 flex flex-col gap-8">
            {/* Header */}
            <div className="w-full flex justify-between items-center">
              <div className="flex flex-col gap-2">
                <h1 className="text-[#23282D] poppins text-3xl font-semibold tracking-tight ">
                {t('DistributerRoute.appointmentPage.title')}
                </h1>
                <p className="inter tracking-tight text-sm color-[#23282D]">
                {t('DistributerRoute.appointmentPage.description')}                </p>
              </div>
            </div>
            <div className="grid grid-cols-12 w-full gap-4 ">
              <div className="col-span-5 w-full bg-white rounded shadow p-3 ">
                <DateCalendar
                  value={values.date}
                  onChange={(newValue) =>
                    setValues({ ...values, date: newValue })
                  }
                  minDate={dayjs()}
                />
              </div>
              <div className="col-span-7 w-full rounded shadow p-5 bg-white flex flex-col gap-8  ">
                <div className="flex flex-col gap-6">
                  <h5 className="poppins text-2xl ">{t('DistributerRoute.appointmentPage.reserveTimeSlot.title')}  </h5>
                  <div className="w-full flex flex-col gap-4 overflow-x-scroll">
                    <div className="w-max gap-6 grid grid-cols-9 py-2  ">
                      {slots.length > 0 &&
                        slots.map((slot) => {
                          return (
                            <div
                              key={slot}
                              className=" flex gap-4 items-center cursor-pointer w-60 "
                              onClick={() =>
                                setValues({
                                  ...values,
                                  time: slot,
                                })
                              }
                            >
                              <div className="border border-black rounded-lg flex items-center justify-center px-2 w-full py-2 poppins ">
                                {slot}
                              </div>
                              <div className="flex p-[2px] w-4 h-4 border border-black rounded-full cursor-pointer ">
                                {values.time === slot && (
                                  <div className="rounded-full bg-[#719FE5] w-full "></div>
                                )}
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                </div>
                <div className="flex flex-col gap-6">
                  <div className="flex justify-between items-center ">
                    <h6 className="poppins text-2xl">{t('DistributerRoute.appointmentPage.agendaSection.title')} </h6>
                    <div className="flex gap-2 items-center ">
                      <p className="poppins text-sm leading-6 ">{t('DistributerRoute.appointmentPage.agendaSection.offline')} </p>
                      <Switch
                        onChange={(e) => {
                          setValues({ ...values, online: e.target.checked });
                        }}
                        checked={values.online}
                      />
                      <p className="text-[#719FE5] poppins text-sm leading-6 ">
                      {t('DistributerRoute.appointmentPage.agendaSection.online')}
                      </p>
                    </div>
                  </div>
                  <TextField
                    multiline
                    rows={2}
                    onChange={(e) =>
                      setValues({ ...values, agenda: e.target.value })
                    }
                  />
                </div>
                <button
                  onClick={handleSubmit}
                  className="w-full py-2 flex items-center justify-center rounded bg-primary text-white poppins leading-6  "
                >
                {t('DistributerRoute.appointmentPage.bookAppointmentButton')}
                </button>
              </div>
            </div>
            <div className="w-full bg-white rounded shadow flex flex-col p-8 gap-12">
              <div className="flex items-center justify-between ">
                <h5 className="poppins text-2xl ">{t('DistributerRoute.appointmentPage.appointmentsSection.title')}</h5>
                <div className="flex gap-6 items-center">
                  <TextField
                    label={t('DistributerRoute.appointmentPage.appointmentsSection.fromLabel')}
                    type="date"
                    size="small"
                    InputLabelProps={{
                      shrink: true, // This makes the label float as you select a date
                    }}
                    onChange={(e) => setStartDate(e.target.value)}
                    value={startDate}
                  />
                  <TextField
                    label={t('DistributerRoute.appointmentPage.appointmentsSection.toLabel')}
                    type="date"
                    size="small"
                    InputLabelProps={{
                      shrink: true, // This makes the label float as you select a date
                    }}
                    onChange={(e) => setEndDate(e.target.value)}
                    value={endDate}
                  />
                </div>
                <button
                  onClick={getAppointments}
                  className="w-40 py-2 flex justify-center rounded-lg shadow items-center text-white poppins bg-primary     "
                >
                  {t('DistributerRoute.appointmentPage.appointmentsSection.viewButton')}
                </button>
              </div>
              <Table
                sx={{
                  borderSpacing: "0 15px !important",
                  borderCollapse: "separate !important",
                }}
              >
                <TableHead>
                  <CustomTableHeadRow>
                    <CustomTableHeadCell>  {t('DistributerRoute.appointmentPage.appointmentsSection.tableHeaders.name')}</CustomTableHeadCell>
                    <CustomTableHeadCell>{t('DistributerRoute.appointmentPage.appointmentsSection.tableHeaders.status')}</CustomTableHeadCell>
                    <CustomTableHeadCell>{t('DistributerRoute.appointmentPage.appointmentsSection.tableHeaders.date')}</CustomTableHeadCell>
                    <CustomTableHeadCell>{t('DistributerRoute.appointmentPage.appointmentsSection.tableHeaders.time')}</CustomTableHeadCell>
                  </CustomTableHeadRow>
                </TableHead>
                <TableBody>
                  {appointments.map((appointment: any) => {
                    return (
                      <CustomTableBodyRow
                        sx={{
                          marginBottom: "10px !important",
                        }}
                      >
                        <CustomTableBodyCell
                          sx={{
                            borderTopLeftRadius: "10px",
                            borderBottomLeftRadius: "10px",
                          }}
                        >
                          {appointment?.approvedBy?.name || "N/A"}
                        </CustomTableBodyCell>
                        <CustomTableBodyCell>
                          {appointment?.status}
                        </CustomTableBodyCell>
                        <CustomTableBodyCell>
                          {moment(appointment.date).format("DD.MM.YYYY")}
                        </CustomTableBodyCell>

                        <CustomTableBodyCell
                          sx={{
                            borderTopRightRadius: "10px",
                            borderBottomRightRadius: "10px",
                          }}
                        >
                          {appointment?.time}
                        </CustomTableBodyCell>
                      </CustomTableBodyRow>
                    );
                  })}
                </TableBody>
              </Table>
            </div>
          </div>
        </LocalizationProvider>
      </Sidebar>
    </>
  );
};

export default Appointment;
