import React, { useState, useEffect } from "react";
import {
  FormContainer,
  TextFieldElement,
  SelectElement,
} from "react-hook-form-mui";
import { toastMessage } from "../../utils/toast";
import Loading from "react-fullscreen-loading";
import { useNavigate } from "react-router-dom";
import Sidebar from "../Layout/Sidebar";
import { callAxios } from "../../utils/axios";
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

type FormProps = {
  resourceId: string;
  email: string;
  field: string;
  remarks: string;
  
};

const PlaceOrder = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const defaultValues: FormProps = {
    resourceId: "  ",
    email: "   ",
    field: "  ",
    remarks: "  "
  };

  const onSubmit = async (values: FormProps) => {
    console.log(values);

  };
  const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });
  

  return (
    <>
      <Loading loading={loading} background="#fff" loaderColor="#5046E5" />
      <Sidebar>
        <div className="py-6 px-8 flex flex-col gap-8">
          <div className="w-full flex justify-between items-center">
            <div className="flex flex-col gap-2">
              <h1 className="text-[#23282D] poppins text-3xl font-semibold tracking-tight ">
                Place Order
              </h1>
              <p className="inter tracking-tight text-sm color-[#23282D]">
              Your one-click destination for a seamless and efficient ordering process, ensuring a straightforward and user-friendly transaction experience.
              </p>
            </div>
       
          </div>
          <div className="w-full bg-[#F9F9F9] flex flex-col p-4 gap-8 rounded ">
            <div className="w-full flex justify-center">
              <p className="poppins text-lg font-light underline">
                Place an Order
              </p>
            </div>
            <FormContainer defaultValues={defaultValues} onSuccess={onSubmit}>
              <div className="flex flex-col gap-8 w-full">
                <div className="w-full grid grid-cols-3 gap-16  ">
                  <div className="w-full">
                    <TextFieldElement
                      name="name"
                      label="Resource ID"
                      required
                      fullWidth
                      sx={{
                        backgroundColor: "#fff",
                      }}
                  
                    />
                  </div>
                  <div className="w-full">
                    <TextFieldElement
                      name="file name"
                      label="File Name"
                      type="name"
                      fullWidth
                      required
                      sx={{
                        backgroundColor: "#fff",
                      }}
                    />
                  </div>
                  <div className="w-full">
                    <SelectElement
                      name="role"
                      label="Field/Category"
                      fullWidth
                      required
                      sx={{
                        backgroundColor: "#fff",
                      }}
                      options={[
                        { label: "Distributor", id: "distributor" },
                        { label: "Dealer", id: "dealer" },
                      ]}
                    />
                  </div>
                </div>
                <div className=" flex flex-col gap-8 w-full"> 
                <div className="w-full grid grid-cols-2 gap-8  ">
                  <div className="box-border h-52 w-78 p-4 border-2 border-dotted flex flex-col ">
                    <div className="text-gray-400 px-28 py-14 "><p>Drag Your File or <Button className=""component="label" variant="contained" startIcon={<CloudUploadIcon />}>
                                Browse
      <VisuallyHiddenInput type="file" />
    </Button>
    <br/>
    (pdf, png, jpeg, svg)</p></div>
                    
                </div>
                  <p className="poppins text-light py-4 ">Description (Optional)<br/><br/>
                  <TextFieldElement
                    className="my-4"
                    name="remarks"
                    label="Under 200 words"
                    multiline
                    rows={4}   
                    fullWidth
                    sx={{
                      backgroundColor: "#fff",
                    }}
                  /></p>
                </div>
                </div>
                <div className="w-1/2 flex flex-col gap-5 text-blue-500 ">
                 <span><u>something.pdf </u></span>
                </div>
                
              </div>
              <div className="w-full mt-20 flex justify-center items-center">
                <button className="rounded bg-[#2186f2] py-2 px-28 poppins text-white ">
                  Submit
                </button>
              </div>
            </FormContainer>
          </div>
        </div>
      </Sidebar>
    </>
  );
};

export default PlaceOrder;