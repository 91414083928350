import React, { useState, useEffect } from "react";
import Sidebar from "../../Layout/Sidebar";
import Loading from "react-fullscreen-loading";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { useNavigate } from "react-router-dom";
import { callAxios } from "../../../utils/axios";
import { toastMessage } from "../../../utils/toast";
import Pagination from "@mui/material/Pagination";
import moment from "moment";
import Modal from "@mui/material/Modal";
import ViewCampaign from "./ViewCampaign";
import { useTranslation } from "react-i18next";

const CustomTableHeadCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: "transparent", // Customize cell background color
  fontWeight: "bold", // Customize cell text styling
  fontFamily: "Poppins",
  fontSize: "16px",
  lineHeight: "24px",
  borderBottom: "none",
  color: "#000",
}));
const CustomTableBodyCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: "#F4F4F4", // Customize cell background color
  fontFamily: "Poppins",
  fontSize: "16px",
  lineHeight: "24px",
  borderBottom: "none",
}));

const CustomTableHeadRow = styled(TableRow)(({ theme }) => ({
  "&:hover": {
    backgroundColor: "#E0E0E0",
  },
  border: "none",
}));
const CustomTableBodyRow = styled(TableRow)(({ theme }) => ({
  marginBottom: "10px",
}));

const CampaignsTable = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState(false);
  const [campaigns, setCampaigns] = useState([] as any);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [openView, setOpenView] = useState(false);
  const [selectedUser, setSelectedUser] = useState({} as any);

  const { t } = useTranslation();

  const getAllCampaigns = async () => {
    try {
      setLoading(true);
      const { data } = await callAxios(
        "get",
        `campaign/getAllCampaigns?page=${page}&limit=10`
      );
      console.log(data);
      setCampaigns(data?.campaigns);
      setLoading(false);
      setTotalPages(data?.totalPages);
    } catch (error) {
      console.log(error);
      setLoading(false);
      toastMessage(
        error?.response?.data?.message || "Something went wrong.",
        "error"
      );
    }
  };

  useEffect(() => {
    getAllCampaigns();
  }, [checked, page]);

  return (
    <>
      <Loading loading={loading} background="#fff" loaderColor="#719FE5" />
      <Sidebar>
        <div className="py-6 px-4 flex flex-col gap-8">
          <div className="w-full flex justify-between items-center">
            <div className="flex flex-col gap-2">
              <h1 className="text-[#23282D] poppins text-3xl font-semibold tracking-tight ">
                {t("adminRoute.marketingCampaigns.title")}
              </h1>
              <p className="inter tracking-tight text-sm color-[#23282D]">
                {t("adminRoute.marketingCampaigns.description")}{" "}
              </p>
            </div>
            <button
              className="flex gap-1 h-16 px-2 items-center justify-center bg-white   rounded-lg poppins leading-6 "
              style={{
                boxShadow: "0px 4px 8px 0px rgba(0, 0, 0, 0.20)",
              }}
              onClick={() => navigate("/admin/create-campaign")}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M12.1094 7V17M17.1094 12L7.10938 12"
                  stroke="#4B4B4B"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <p className="wrap break-words">
                {" "}
                {t("adminRoute.marketingCampaigns.addNewCampaignButton")}
              </p>
            </button>
          </div>
          <div className="w-full flex flex-col gap-3 ">
            <div className="w-full bg-white p-3 rounded shadow">
              <Table
                sx={{
                  borderSpacing: "0 15px !important",
                  borderCollapse: "separate !important",
                }}
              >
                <TableHead>
                  <CustomTableHeadRow>
                    <CustomTableHeadCell>
                      {" "}
                      {t(
                        "adminRoute.marketingCampaigns.tableHeaders.campaignName"
                      )}
                    </CustomTableHeadCell>
                    <CustomTableHeadCell>
                      {t(
                        "adminRoute.marketingCampaigns.tableHeaders.description"
                      )}
                    </CustomTableHeadCell>
                    <CustomTableHeadCell>
                      {t(
                        "adminRoute.marketingCampaigns.tableHeaders.createdBy"
                      )}
                    </CustomTableHeadCell>
                    <CustomTableHeadCell>
                      {t(
                        "adminRoute.marketingCampaigns.tableHeaders.createdAt"
                      )}
                    </CustomTableHeadCell>
                    <CustomTableHeadCell></CustomTableHeadCell>
                  </CustomTableHeadRow>
                </TableHead>
                <TableBody>
                  {campaigns?.map((campaign: any) => {
                    return (
                      <CustomTableBodyRow
                        sx={{
                          marginBottom: "10px !important",
                        }}
                        key={campaign?._id}
                      >
                        <CustomTableBodyCell
                          sx={{
                            borderTopLeftRadius: "10px",
                            borderBottomLeftRadius: "10px",
                          }}
                        >
                          {campaign.name}
                        </CustomTableBodyCell>
                        <CustomTableBodyCell>
                          {campaign?.description.slice(0, 50)}
                        </CustomTableBodyCell>
                        <CustomTableBodyCell>
                          {campaign?.createdBy?.name}
                        </CustomTableBodyCell>

                        <CustomTableBodyCell
                          sx={{
                            borderTopRightRadius: "10px",
                            borderBottomRightRadius: "10px",
                          }}
                        >
                          {moment(campaign?.createdAt).format("DD.MM.YYYY")}
                        </CustomTableBodyCell>
                        <CustomTableHeadCell>
                          <div className="w-full flex justify-center">
                            <button
                              onClick={() => {
                                setSelectedUser(campaign);
                                setOpenView(true);
                              }}
                              className="bg-primary py-2 px-8 rounded-md text-white poppins text-sm font-normal"
                            >
                              {t(
                                "adminRoute.marketingCampaigns.tableHeaders.viewButton"
                              )}
                            </button>
                          </div>
                        </CustomTableHeadCell>
                      </CustomTableBodyRow>
                    );
                  })}
                </TableBody>
              </Table>
            </div>
            <div className="w-full flex justify-end">
              <Pagination
                count={totalPages}
                variant="outlined"
                shape="rounded"
                color="primary"
                onChange={(e, value) => setPage(value)}
              />
            </div>
          </div>
        </div>
        <Modal
          open={openView}
          onClose={() => setOpenView(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <ViewCampaign
            checked={checked}
            setChecked={setChecked}
            setOpen={setOpenView}
            existingUser={selectedUser}
          />
        </Modal>
      </Sidebar>
    </>
  );
};

export default CampaignsTable;
