import React, { useState, useEffect } from "react";
import Sidebar from "../../Layout/Sidebar";
import Loading from "react-fullscreen-loading";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { useNavigate } from "react-router-dom";
import { callAxios } from "../../../utils/axios";
import { toastMessage } from "../../../utils/toast";
import { useConfirm } from "material-ui-confirm";
import { MenuItem, Modal, Select, Switch, TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { InputAdornment } from "@mui/material";
import OutlinedInput from "@mui/material/OutlinedInput";
import moment from "moment";
import { useTranslation } from "react-i18next";
import ViewProduct from "./ViewProduct";
const CustomTableHeadCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: "#fff", // Customize cell background color
  fontWeight: "bold", // Customize cell text styling
  fontFamily: "Poppins",
  fontSize: "16px",
  lineHeight: "24px",
  borderBottom: "none",
  color: "black",
}));
const CustomTableBodyCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: "#F4F4F4", // Customize cell background color
  fontFamily: "Poppins",
  fontSize: "16px",
  lineHeight: "24px",
  borderBottom: "none",
}));

const CustomTableHeadRow = styled(TableRow)(({ theme }) => ({
  "&:hover": {
    backgroundColor: "#E0E0E0",
  },
  border: "none",
}));
const CustomTableBodyRow = styled(TableRow)(({ theme }) => ({
  marginBottom: "10px",
}));

const ProductsTable = () => {
  const navigate = useNavigate();
  const confirm = useConfirm();
  const {t} = useTranslation()

  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState(false);
  const [selectedUser, setSelectedUser] = useState({} as any);
  const [products, setProducts] = useState([] as any);
  const [openView, setOpenView] = useState(false);

  const getAllProducts = async () => {
    try {
      setLoading(true);
      const { data } = await callAxios("get", "product/getAllProducts");
      console.log(data);
      setProducts(data?.products);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      toastMessage(
        error?.response?.data?.message || "Something went wrong.",
        "error"
      );
    }
  };

  useEffect(() => {
    getAllProducts();
  }, [checked]);

  const handleChangeStatus = async (e: any, id: any) => {
    
    console.log(e.target.checked);

    try {
      setLoading(true);
      const { data } = await callAxios("put", `product/changeStatus/${id}`, {
        status: e.target.checked ? "Active" : "Inactive",
      });
      console.log(data);
      setLoading(false);
      toastMessage(data.message, "success");
      setChecked(!checked);
    } catch (error) {
      console.log(error);
      setLoading(false);
      toastMessage(
        error?.response?.data?.message || "Something went wrong.",
        "error"
      );
    }
  };

  return (
    <>
      <Loading loading={loading} background="#fff" loaderColor="#719FE5" />
      <Sidebar>
        <div className="py-6 px-4 flex flex-col gap-8">
          <div className="w-full flex justify-between items-center">
            <div className="flex flex-col gap-2">
              <h1 className="text-[#23282D] poppins text-3xl font-semibold tracking-tight ">
              {t('adminRoute.displayProducts.title')}
              </h1>
              <p className="inter tracking-tight text-sm color-[#23282D]">
              {t('adminRoute.displayProducts.description')}              </p>
            </div>
            <button
              className="flex gap-1 h-16 px-2 items-center justify-center bg-white   rounded-lg poppins leading-6 "
              style={{
                boxShadow: "0px 4px 8px 0px rgba(0, 0, 0, 0.20)",
              }}
              onClick={() => navigate("/admin/create-product")}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M12.1094 7V17M17.1094 12L7.10938 12"
                  stroke="#4B4B4B"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <p className="wrap break-words">{t('adminRoute.displayProducts.addNewProductButton.label')} </p>
            </button>
          </div>
          <div className="w-full flex flex-col gap-3 ">
            <div className="w-full bg-white p-3 rounded shadow">
              <Table
                sx={{
                  borderSpacing: "0 15px !important",
                  borderCollapse: "separate !important",
                }}
              >
                <TableHead>
                  <CustomTableHeadRow>
                    <CustomTableHeadCell>{t('adminRoute.displayProducts.tableHeaders.productName')} </CustomTableHeadCell>
                    <CustomTableHeadCell>{t('adminRoute.displayProducts.tableHeaders.skuCode')}</CustomTableHeadCell>
                    <CustomTableHeadCell>{t('adminRoute.displayProducts.tableHeaders.quantity')}</CustomTableHeadCell>
                    <CustomTableHeadCell>{t('adminRoute.displayProducts.tableHeaders.onboardedBy')}</CustomTableHeadCell>
                    <CustomTableHeadCell>{t('adminRoute.displayProducts.tableHeaders.inactive')}</CustomTableHeadCell>
                    <CustomTableHeadCell></CustomTableHeadCell>
                    <CustomTableHeadCell></CustomTableHeadCell>
                  </CustomTableHeadRow>
                </TableHead>
                <TableBody>
                  {products.map((product: any) => {
                    return (
                      <CustomTableBodyRow
                        sx={{
                          marginBottom: "10px !important",
                        }}
                        key={product?._id}
                      >
                        <CustomTableBodyCell
                          sx={{
                            borderTopLeftRadius: "10px",
                            borderBottomLeftRadius: "10px",
                          }}
                        >
                          {product.skuName}
                        </CustomTableBodyCell>
                        <CustomTableBodyCell>
                          {product?.skuCode}
                        </CustomTableBodyCell>
                        <CustomTableBodyCell>
                          {product?.quantity}
                        </CustomTableBodyCell>
                        <CustomTableBodyCell>
                          {product?.createdBy?.name}
                        </CustomTableBodyCell>
                        <CustomTableBodyCell
                          sx={{
                            borderTopRightRadius: "10px",
                            borderBottomRightRadius: "10px",
                          }}
                        >
                          <Switch
                            checked={product?.status === "Active"}
                            onChange={(e) =>
                              handleChangeStatus(e, product?._id)
                            }
                          />
                        </CustomTableBodyCell>
                        <CustomTableHeadCell>
                          <div className="w-full flex justify-center">
                            <button
                              onClick={() => {
                                console.log(product);
                                setSelectedUser(product);
                                setOpenView(true);
                              }}
                              className="bg-primary py-2 px-8 rounded-md text-white poppins text-sm font-normal"
                            >
                             {t('adminRoute.displayProducts.tableHeaders.actions.view')}
                            </button>
                          </div>
                        </CustomTableHeadCell>
                        <CustomTableHeadCell>
                          <img
                            src="/assets/Icons/edit.svg"
                            alt="edit"
                            className="w-8 h-8 cursor-pointer"
                            onClick={() => {
                              navigate(`/admin/edit-product/${product?._id}`);
                            }}
                          />
                        </CustomTableHeadCell>
                      </CustomTableBodyRow>
                    );
                  })}
                </TableBody>
              </Table>
            </div>
          </div>
        </div>
        <Modal
          open={openView}
          onClose={() => setOpenView(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <ViewProduct
            checked={checked}
            setChecked={setChecked}
            setOpen={setOpenView}
            existingUser={selectedUser}
          />
        </Modal>
      </Sidebar>
    </>
  );
};

export default ProductsTable;
