import React from "react";
import Sidebar from "../Layout/Sidebar";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

import { TextField } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useTranslation } from "react-i18next";
const data = [
  {
    name: "Page A",
    uv: 1000,
    pv: 2400,
    amt: 2400,
  },
  {
    name: "Page B",
    uv: 3000,
    pv: 1398,
    amt: 2210,
  },
  {
    name: "Page C",
    uv: 2000,
    pv: 9800,
    amt: 2290,
  },
  {
    name: "Page D",
    uv: 2780,
    pv: 3908,
    amt: 2000,
  },
  {
    name: "Page E",
    uv: 1890,
    pv: 4800,
    amt: 2181,
  },
  {
    name: "Page F",
    uv: 2390,
    pv: 3800,
    amt: 2500,
  },
  {
    name: "Page G",
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
];

const SalesPerformance = () => {

  const {t} = useTranslation();

  
  return (
    <Sidebar>
      <div className="py-6 px-4 flex flex-col gap-8">
        <div className="flex flex-col gap-2">
          <h1 className="text-[#23282D] poppins text-3xl font-semibold tracking-tight ">
           {t('DistributerRoute.salesPerformancepg.salesAnalytics.title')}
          </h1>
          <p className="inter tracking-tight text-sm color-[#23282D]" >
          {t('DistributerRoute.salesPerformancepg.salesAnalytics.description')} </p>
        </div>
        <div className="w-full grid grid-cols-12 gap-12  ">
          {/* Left Container */}
          <div className="col-span-5 flex flex-col gap-3 w-full   ">
            <div className="flex flex-col gap-3 bg-white shadow-xl rounded">
              <div className="flex w-full items-center justify-between p-4">
                <div className="flex flex-col gap-2">
                  <h3 className="text-[##2C384A] poppins  text-xl leading-7 font-semibold">
                  {t('DistributerRoute.salesPerformancepg.saleSection.title')}
                  </h3>
                  <p className="poppins leading-5 text-[#2C384A38] ">
                  {t('DistributerRoute.salesPerformancepg.saleSection.period')}
                  </p>
                </div>
                <h3 className="text-[#5046E5] font-poppins text-xl font-semibold leading-9 ">
                {t('DistributerRoute.salesPerformancepg.saleSection.value')}
                </h3>
              </div>
              <div className="w-full">
                <ResponsiveContainer width="100%" height={150}>
                  <AreaChart
                    width={400}
                    height={60}
                    data={data}
                    margin={{
                      top: 5,
                      right: 0,
                      left: 0,
                      bottom: 5,
                    }}
                  >
                    <Area
                      type="monotone"
                      dataKey="uv"
                      stroke="#5046E5"
                      fill="#8884d8"
                      strokeWidth={2}
                    />
                  </AreaChart>
                </ResponsiveContainer>
              </div>
            </div>
            <div className="w-full flex gap-4 ">
              <div className="bg-white w-full p-3 rounded shadow-lg flex flex-col gap-4">
                <div className="flex items-center gap-1 justify-center">
                  <p className="text-[#2C384A36] poppins text-lg leading-6 ">
                  {t('DistributerRoute.salesPerformancepg.saleSection.customers.label')}
                  </p>
                  <img src="/assets/Logo/users-sale.svg" alt="icon" />
                </div>
                <p className="text-[#2C384A95] text-xl leading-9 ">44.725</p>
                <p className="text-[#EF376E] text-sm leading-5 ">(-12.4%)</p>
              </div>
              <div className="bg-white w-full p-3 rounded shadow-lg flex flex-col gap-4">
                <div className="flex items-center gap-1 justify-center">
                  <p className="text-[#2C384A36] poppins text-lg leading-6 ">
                  {t('DistributerRoute.salesPerformancepg.saleSection.orders.label')}
                  </p>
                  <img src="/assets/Logo/cart-sale.svg" alt="icon" />
                </div>
                <p className="text-[#2C384A95] text-xl leading-9 ">44.725</p>
                <p className="text-[#51CC8A] text-sm leading-5 ">(+12.4%)</p>
              </div>
            </div>
          </div>
          {/* Right Container */}
          <div className="col-span-7 w-full shadow-xl rounded-lg p-8 relative bg-white ">
            <table className="table-auto w-full border-separate border-spacing-y-3">
              <tbody>
                <tr
                  className="poppins text-xl font-medium leading-6 border-b border-b-slate-200 "
                  style={{ borderBottom: "1px solid black" }}
                >
                  <td className="font-medium text-center py-2  ">1</td>
                  <td className="font-light text-center  ">Product 1</td>
                  <td className="font-medium text-center  ">59,845</td>
                </tr>
                <tr className="poppins text-xl font-medium leading-6 border-b border-b-slate-200 ">
                  <td className="font-medium text-center py-2  ">2</td>
                  <td className="font-light text-center  ">Product 2</td>
                  <td className="font-medium text-center  ">59,845</td>
                </tr>
                <tr className="poppins text-xl font-medium leading-6 border-b border-b-slate-200 ">
                  <td className="font-medium text-center py-2 ">3</td>
                  <td className="font-light text-center ">Product 3</td>
                  <td className="font-medium text-center ">59,845</td>
                </tr>
              </tbody>
            </table>
          </div>
          
        </div>
        <div className="w-full p-6 flex flex-col gap-8 bg-white rounded-lg ">
          <div className="flex w-full justify-between items-center">
            <p className="poppins font-light text-2xl ">{t('DistributerRoute.salesPerformancepg.orderHistory.title')}</p>
            <div className="flex gap-4">
              <TextField
                variant="outlined"
                // type="date"
                placeholder={t('DistributerRoute.salesPerformancepg.orderHistory.from')}
                size="small"
                fullWidth
              />
              <TextField
                variant="outlined"
                // type="date"
                placeholder={t('DistributerRoute.salesPerformancepg.orderHistory.to')}
                size="small"
                fullWidth
              />
            </div>
            <button className="bg-primary rounded py-2 px-16 text-sm inter font-light text-white inter leading-6">
            {t('DistributerRoute.salesPerformancepg.orderHistory.viewButton')}
            </button>
          </div>
          <div className="w-full">
            <table className="table-auto w-full border-separate border-spacing-y-3">
              <thead className="bg-transparent">
                <tr className=" poppins font-medium text-xl leading-6 p-2">
                  <td className="py-3 px-4">{t('DistributerRoute.salesPerformancepg.tableHeadings.orderId')}</td>
                  <td>{t('DistributerRoute.salesPerformancepg.tableHeadings.status')}</td>
                  <td>{t('DistributerRoute.salesPerformancepg.tableHeadings.date')}</td>
                </tr>
              </thead>
              <tbody>
                <tr
                  className="poppins text-xl font-light bg-[#f4f4f4] rounded-lg py-2 my-1"
                 
                >
                  <td className="py-3 px-4">#182728</td>
                  <td className="">Pending</td>
                  <td className="">26.7.3</td>
                </tr>
                <tr className="poppins text-xl font-light bg-[#f4f4f4] rounded-lg py-2 my-1">
                  <td className="py-3 px-4">#182728</td>
                  <td className="">Pending</td>
                  <td className="">26.7.3</td>
                </tr>
                <tr className="poppins text-xl font-light bg-[#f4f4f4] rounded-lg py-2 mb-1">
                  <td className="py-3 px-4">#182728</td>
                  <td className="">Pending</td>
                  <td className="">26.7.3</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </Sidebar>
  );
};

export default SalesPerformance;
