import React, { useState, useEffect } from "react";
import { toastMessage } from "../../../utils/toast";
import Loading from "react-fullscreen-loading";
import { useNavigate } from "react-router-dom";
import Sidebar from "../../Layout/Sidebar";
import { callAxios, backendUrl } from "../../../utils/axios";
import { FormContainer, TextFieldElement } from "react-hook-form-mui";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import axios from "axios";
import { useTranslation } from "react-i18next";

type FormProps = {
  name: string;
  description: string;
  file: string;
};

const CreateCampaign = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [file, setFile] = useState(null);

  const {t} = useTranslation()

  const handleFileChange = (e: any) => {
    setFile(e.target.files[0]);
  };

  const defaultValues: FormProps = {
    name: "",
    description: "",
    file: "",
  };

  const onSubmit = async (values: FormProps) => {
    console.log(values);

    if (!file) return toastMessage("Please select a file", "error");

    try {
      setLoading(true);

      const formData = new FormData();
      formData.append("file", file);

      const { data: result } = await axios.post(
        `${backendUrl}/upload/singleFileUpload`,
        formData
      );

      values.file = result.fileUrl;

      const { data } = await callAxios(
        "post",
        "campaign/createCampaign",
        values
      );
      console.log(data);
      toastMessage("Campaign sent Successfully", "success");
      setLoading(false);
      navigate("/admin/marketing-campaigns");
    } catch (error) {
      console.log(error);
      setLoading(false);
      toastMessage(
        error?.response?.data?.message || "Something went wrong.",
        "error"
      );
    }
  };

  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  return (
    <>
      <Loading loading={loading} background="#fff" loaderColor="#719FE5" />

      <Sidebar>
        <div className="py-6 px-8 flex flex-col gap-8">
          <div className="w-full flex justify-between items-center">
            <div className="flex flex-col gap-2">
              <h1 className="text-[#23282D] poppins text-3xl font-semibold tracking-tight ">
              {t('adminRoute.marketingCampaigns.createCampaign.pageTitle')}
              </h1>
              <p className="inter tracking-tight text-sm color-[#23282D]">
              {t('adminRoute.marketingCampaigns.createCampaign.pageDescription')}
              </p>
            </div>
          </div>
          <div className="w-full bg-[#fff] flex flex-col p-4 gap-8 rounded">
            <FormContainer defaultValues={defaultValues} onSuccess={onSubmit}>
              <div className="flex flex-col gap-8 w-full">
                <div className="w-full flex justify-center">
                  <h6 className="text-[#23282D] text-2xl poppins font-medium underline">
                  {t('adminRoute.marketingCampaigns.createCampaign.form.title')}
                  </h6>
                </div>
                <div className="w-full grid grid-cols-2 gap-16">
                  <TextFieldElement
                    name="name"
                    label={t('adminRoute.marketingCampaigns.createCampaign.form.nameLabel')}
                    required
                    fullWidth
                    size="small"
                  />
                  <TextFieldElement
                    name="description"
                    label={t('adminRoute.marketingCampaigns.createCampaign.form.descriptionLabel')}
                    required
                    size="small"
                    fullWidth
                    multiline
                    rows={3}
                  />
                </div>
                <div className="w-full gap-8  ">
                  <div className="box-border h-52 w-78 p-4 border-2 border-dotted border-gray-300 flex flex-col items-center justify-center ">
                    <div className="flex gap-2 items-center ">
                      <p className="poppins text-sm leading-2 text-gray-400">
                      {t('adminRoute.marketingCampaigns.createCampaign.form.fileInputLabel')}{" "}
                      </p>
                      <Button
                        className=""
                        component="label"
                        variant="contained"
                        size="small"
                        sx={{ color: "white" }}
                      >
                   browse
                        <VisuallyHiddenInput
                          type="file"
                          required
                          onChange={handleFileChange}
                        />
                      </Button>
                    </div>

                    <br />
                    <div className="poppins text-sm leading-2 text-gray-400">
                      {" "}
                      {t('adminRoute.marketingCampaigns.createCampaign.form.fileInputNote')}
                    </div>
                  </div>
                </div>
                <div className="w-full  flex justify-end items-center">
                  <button className="rounded bg-primary py-2 px-16 poppins text-white ">
                  {t('adminRoute.marketingCampaigns.createCampaign.form.submitButton')}
                  </button>
                </div>
              </div>
            </FormContainer>
          </div>
        </div>
      </Sidebar>
    </>
  );
};

export default CreateCampaign;
