import React, { useState, useEffect } from "react";
import {
  FormContainer,
  TextFieldElement,
  SelectElement,
} from "react-hook-form-mui";
import { toastMessage } from "../../utils/toast";
import Loading from "react-fullscreen-loading";
import { useNavigate } from "react-router-dom";
import Sidebar from "../Layout/Sidebar";
import { callAxios } from "../../utils/axios";
import { useTranslation } from "react-i18next";

type FormProps = {
  name: string;
  address1: string;
  address2: string;
  role: string;
  branch: string;
};

const AddShippingAddress = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const {t} = useTranslation()

  const defaultValues: FormProps = {
    name: "",
    address1: "",
    address2: "",
    role: "",
    branch: "",
  };
  const [checked, setChecked] = useState(false);
  const [users, setUsers] = useState([] as any);
  const getAllUsers = async () => {
    try {
      setLoading(true);
      const { data } = await callAxios("get", "user/getUsers");
      console.log(data);
      let options = [];
      data?.users.forEach((user: any) => {
        options.push({ id: user._id, label: user.name });
      });
      setUsers(options);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      toastMessage(
        error?.response?.data?.message || "Something went wrong.",
        "error"
      );
    }
  };

  useEffect(() => {
    getAllUsers();
  }, [checked]);

  const onSubmit = async (values: FormProps) => {
    console.log(values);

    try {
      setLoading(true);
      const { data } = await callAxios("post", "address/createAddress", values);
      console.log(data);
      toastMessage("Shipping Address Created Successfully", "success");
      setLoading(false);
      navigate("/distributor/account-management");
    } catch (error) {
      console.log(error);
      setLoading(false);
      toastMessage(
        error?.response?.data?.message || "Something went wrong.",
        "error"
      );
    }
  };

  return (
    <>
      <Loading loading={loading} background="#fff" loaderColor="#5046E5" />
      <Sidebar>
        <div className="py-6 px-8 flex flex-col gap-8">
          <div className="w-full flex justify-between items-center">
            <div className="flex flex-col gap-2">
              <h1 className="text-[#23282D] poppins text-3xl font-semibold tracking-tight ">
               {t('DistributerRoute.addShippingPage.title')}
              </h1>
              <p className="inter tracking-tight text-sm color-[#23282D]">
              {t('DistributerRoute.addShippingPage.description')}</p>
            </div>
          </div>
          <div className="w-full bg-[#F9F9F9] flex flex-col p-4 gap-8 rounded ">
            <div className="w-full flex justify-center">
              <p className="poppins text-lg font-light underline">
              {t('DistributerRoute.addShippingPage.manageShippingLink.text')}
              </p>
            </div>
            <FormContainer defaultValues={defaultValues} onSuccess={onSubmit}>
              <div className="flex flex-col gap-16 w-full">
                <div className="w-full grid grid-cols-3 gap-16  ">
                  <div className="w-full">
                    <TextFieldElement
                      name="name"
                      label={t('DistributerRoute.addShippingPage.form.name.label')}
                      required
                      fullWidth
                      sx={{
                        backgroundColor: "#fff",
                      }}
                    />
                  </div>
                </div>
                <div className="flex flex-col gap-4 w-full">
                  <div className="w-full grid grid-cols-3 gap-16  ">
                    <div className="w-full">
                      <TextFieldElement
                        name="address1"
                        label={t('DistributerRoute.addShippingPage.form.addressLine1.label')}
                        required
                        fullWidth
                        sx={{
                          backgroundColor: "#fff",
                        }}
                      />
                    </div>
                  </div>
                  <div className="w-full grid grid-cols-3 gap-16  ">
                    <div className="w-full">
                      <TextFieldElement
                        name="address2"
                        label={t('DistributerRoute.addShippingPage.form.addressLine2.label')}
                        required
                        fullWidth
                        sx={{
                          backgroundColor: "#fff",
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="w-full grid grid-cols-3 gap-16  ">
                  <div className="w-full">
                    <SelectElement
                      name="role"
                      label={t('DistributerRoute.addShippingPage.form.selectRole.label')}
                      fullWidth
                      required
                      sx={{
                        backgroundColor: "#fff",
                      }}
                      options={users}
                    />
                  </div>
                  <div className="w-full">
                    <SelectElement
                      name="branch"
                      label={t('DistributerRoute.addShippingPage.form.selectBranch.label')}
                      fullWidth
                      sx={{
                        backgroundColor: "#fff",
                      }}
                      options={[
                        { id: "Sonipat", label: "Sonipat" },
                        { id: "Panipat", label: "Panipat" },
                      ]}
                    />
                  </div>
                </div>
              </div>
              <div className="w-full mt-20 flex justify-center items-center">
                <button className="rounded bg-[#92BE5A] py-2 px-6 poppins text-white ">
                {t('DistributerRoute.addShippingPage.form.submitButton')}
                </button>
              </div>
            </FormContainer>
          </div>
        </div>
      </Sidebar>
    </>
  );
};

export default AddShippingAddress;
