import React from "react";
import {
  FormContainer,
  RadioButtonGroup,
  TextFieldElement,
} from "react-hook-form-mui";

type FormProps = {
  boxHeight: number;
  boxWidth: number;
  boxWeight: number;
  returnable: string;
  fragile: string;
};

const AdditionalDetails = ({
  step,
  setStep,
  formData,
  setFormData,
  finalSubmit,
  existingData
}: any) => {
  const defaultValues: FormProps = {
    boxHeight: existingData?.boxHeight,
    boxWidth: existingData?.boxWidth,
    boxWeight: existingData?.boxWeight,
    returnable: existingData?.returnable,
    fragile: existingData?.fragile,
  };

  const onSubmit = (values: FormProps) => {
    console.log(values);
    finalSubmit(values);
  };

  return (
    <div className="w-full flex flex-col gap-6 py-4 ">
      <h6 className="text-[#23282D] poppins text-2xl font-medium leading-9 tracking-tight">
        Box Details
      </h6>
      <FormContainer defaultValues={defaultValues} onSuccess={onSubmit}>
        <div className="flex flex-col gap-8 w-full">
          <div className="w-full grid grid-cols-3 gap-16 ">
            <div className="w-full flex flex-col gap-1">
              <TextFieldElement
                name="boxHeight"
                placeholder="Height"
                required
                fullWidth
              />
            </div>
            <div className="w-full flex flex-col gap-1">
              <TextFieldElement
                name="boxWidth"
                placeholder="Width"
                required
                fullWidth
              />
            </div>
            <div className="w-full flex flex-col gap-1">
              <TextFieldElement
                name="boxWeight"
                placeholder="Weight"
                required
                fullWidth
              />
            </div>
          </div>
          <div className="w-full flex gap-8 items-center">
            <h6 className="text-[#23282D] poppins text-2xl font-medium leading-9 tracking-tight">
              Returnable
            </h6>
            <RadioButtonGroup
              name="returnable"
              options={[
                {
                  id: "Yes",
                  label: "Yes",
                },
                {
                  id: "No",
                  label: "No",
                },
              ]}
              row
            />
          </div>
          <div className="w-full flex gap-8 items-center">
            <h6 className="text-[#23282D] poppins text-2xl font-medium leading-9 tracking-tight">
              Fragile
            </h6>
            <RadioButtonGroup
              name="fragile"
              options={[
                {
                  id: "Yes",
                  label: "Yes",
                },
                {
                  id: "No",
                  label: "No",
                },
              ]}
              row
            />
          </div>
          <button
            className="rounded bg-primary py-2 px-16 poppins text-white w-fit "
            type="submit"
          >
            Continue
          </button>
        </div>
      </FormContainer>
    </div>
  );
};

export default AdditionalDetails;
